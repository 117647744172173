import React, { useState } from 'react';
import FilterOptions from '../../../lib/FilterOptions';
import BlogArticleFetcher from '../../../lib/BlogArticleFetcher';
import TextButton, { ButtonColor } from '../../../../../../../../design/1/js/templates/atoms/textButton';
import GalleryFilter from '../galleryFilter';
import GalleryView from '../galleryView';
import './index.scss';
const defaultLimit = 10;
const Gallery = ({ className = '', initialData, loadUrl, themes, }) => {
    const baseClassName = 'w-blog-gallery';
    const [articles, setArticles] = useState(initialData);
    const [filterOptions, setFilterOptions] = useState(() => new FilterOptions(null));
    const [offset, setOffset] = useState(initialData.length);
    const [hasMore, setHasMore] = useState(true);
    const blogArticleFetcher = new BlogArticleFetcher(loadUrl);
    const fetchMore = (requestFilterOptions, resetArticles = false) => {
        const requestOffset = resetArticles ? 0 : offset;
        blogArticleFetcher.fetchArticles(defaultLimit, requestOffset, requestFilterOptions)
            .then(response => {
            const newArticles = resetArticles ? [] : articles.slice();
            newArticles.push(...response.articles);
            setArticles(newArticles);
            setHasMore(response.hasMore);
            setOffset(requestOffset + defaultLimit);
        });
    };
    const filterCallback = (options) => {
        setFilterOptions(options);
        fetchMore(options, true);
    };
    const loadMoreCallback = () => {
        fetchMore(filterOptions);
    };
    return (React.createElement("div", { className: `${baseClassName} ${className}` },
        React.createElement(GalleryFilter, { className: `${baseClassName}__filter`, filterCallback: filterCallback, themes: themes }),
        React.createElement(GalleryView, { className: `${baseClassName}__view`, articles: articles }),
        hasMore && (React.createElement("div", { className: `${baseClassName}__moreButton` },
            React.createElement(TextButton, { color: ButtonColor.Primary, showArrow: false, handleClick: loadMoreCallback }, "Mehr anzeigen")))));
};
export default Gallery;
