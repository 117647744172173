var Breakpoint;
(function (Breakpoint) {
    Breakpoint[Breakpoint["XSmall"] = 0] = "XSmall";
    Breakpoint[Breakpoint["Small"] = 576] = "Small";
    Breakpoint[Breakpoint["Medium"] = 768] = "Medium";
    Breakpoint[Breakpoint["Large"] = 992] = "Large";
    Breakpoint[Breakpoint["XLarge"] = 1200] = "XLarge";
    Breakpoint[Breakpoint["XXLarge"] = 1400] = "XXLarge";
})(Breakpoint || (Breakpoint = {}));
export default Breakpoint;
