import React from 'react';
import ReactDOM from 'react-dom';
import RelatedElements from './templates/organisms/relatedElements';
document.addEventListener('DOMContentLoaded', () => {
    const relatedArticlesList = document.querySelectorAll('.plf-related');
    for (const relatedArticles of relatedArticlesList) {
        const relatedElements = JSON.parse(relatedArticles.dataset.relatedArticles);
        const type = relatedArticles.dataset.type;
        ReactDOM.render(React.createElement(RelatedElements, { relatedElements: relatedElements, type: type }), relatedArticles);
    }
});
