/**
 * Fixes window.matchMedia().addEventListener and .removeEventListener in Safari < 14
 *
 * @see Derived and simplified from https://github.com/angular/zone.js/pull/1003
 */
export default function mediaQueryListenerPolyfill() {
    if (supportsMediaQueryListEvents()) {
        return;
    }
    const mediaQueryListPrototype = getMediaQueryListPrototype();
    mediaQueryListPrototype.addEventListener = function (type, listener) {
        this.addListener(listener);
    };
    mediaQueryListPrototype.removeEventListener = function (type, listener) {
        this.removeListener(listener);
    };
}
// Safari doesn't support accessing the prototype directly at window.MediaQueryList
const getMediaQueryListPrototype = () => {
    const mediaQueryList = window.matchMedia('');
    return Object.getPrototypeOf(mediaQueryList);
};
const supportsMediaQueryListEvents = () => window.MediaQueryList && window.MediaQueryList.prototype.addEventListener;
