import React from 'react';
import Linkbox from '../../../../../../../../design/1/js/templates/organisms/linkbox';
import { ButtonColor } from '../../../../../../../../design/1/js/templates/atoms/textButton';
const JobAdverts = ({ className = '', jobs }) => {
    const baseClassName = 'w-job-jobAdverts';
    return (React.createElement("div", { className: `${baseClassName} ${className}` }, jobs.map((job, index) => (React.createElement(Linkbox
    // eslint-disable-next-line react/no-array-index-key
    , { 
        // eslint-disable-next-line react/no-array-index-key
        key: index, buttonColor: ButtonColor.Secondary, buttonText: job.placeholder ? 'Spontan bewerben' : 'Mehr erfahren', lead: job.lead, hasButton: true, isExternalLink: false, rubric: job.department, title: job.title, url: job.url })))));
};
export default JobAdverts;
