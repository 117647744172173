import React, { useRef } from 'react';
import ImageResizer, { cropModelToBackgroundPosition } from '../../../lib/ImageResizer';
import './index.scss';
import ScrollButton from '../../atoms/scrollButton';
import useElementDimensions from '../../../lib/hooks/useElementDimensions';
const ImageHeader = ({ animateScrollButton = true, children, className = '', cropModel, hasScrollButton = false, image, }) => {
    const baseClassName = 'm-imageHeader';
    const headerRef = useRef(null);
    const imageDimensions = useElementDimensions(headerRef);
    const onClickScrollButton = () => {
        if (headerRef.current !== null) {
            const headerBottom = document.documentElement.scrollTop + headerRef.current.getBoundingClientRect().bottom;
            window.scrollTo({
                behavior: 'smooth',
                top: headerBottom,
            });
        }
    };
    let backgroundUrl = null;
    if (imageDimensions !== null) {
        const imageResizer = new ImageResizer(image);
        backgroundUrl = imageResizer.resize(imageDimensions.width, imageDimensions.height, cropModel);
    }
    return (React.createElement("div", { className: `${baseClassName} ${className} full-width-mobile-content`, ref: headerRef },
        React.createElement("div", { className: `${baseClassName}__image`, style: {
                backgroundImage: backgroundUrl && `url(${backgroundUrl})`,
                backgroundPosition: cropModelToBackgroundPosition(cropModel),
            } }),
        React.createElement("div", { className: `${baseClassName}__content background-dark` },
            children,
            hasScrollButton && (React.createElement(ScrollButton, { animate: animateScrollButton, className: `${baseClassName}__scrollButton`, onClick: onClickScrollButton })))));
};
export default ImageHeader;
