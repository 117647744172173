import React from 'react';
import ImageResizer from '../../../../../../../../design/1/js/lib/ImageResizer';
import ContainerSizeMap from '../../../../../../../../design/1/js/lib/types/ContainerSizeMap';
import './index.scss';
import Rubric from '../../atoms/rubric';
import Title from '../../atoms/title';
import CircularButton from '../../../../../../../../design/1/js/templates/atoms/circularButton';
import { ButtonColor } from '../../../../../../../../design/1/js/templates/atoms/textButton';
import useBreakpoint from '../../../../../../../../design/1/js/lib/hooks/useBreakpoint';
var TileSize;
(function (TileSize) {
    TileSize["DesktopSmall"] = "desktopSmall";
    TileSize["DesktopBig"] = "desktopBig";
    TileSize["DesktopTall"] = "desktopTall";
    TileSize["TabletNormal"] = "tabletNormal";
    TileSize["TabletWide"] = "tabletWide";
    TileSize["Mobile"] = "mobile";
})(TileSize || (TileSize = {}));
var TileAspectRatio;
(function (TileAspectRatio) {
    TileAspectRatio[TileAspectRatio["Desktop"] = 1.25] = "Desktop";
    TileAspectRatio[TileAspectRatio["Mobile"] = 1.7777777777777777] = "Mobile";
})(TileAspectRatio || (TileAspectRatio = {}));
const Tile = ({ article, className = '', tileSize, }) => {
    const baseClassName = 'w-blog-tile';
    const breakpoint = useBreakpoint();
    const imageFormatter = new ImageResizer(article.image);
    const imageSize = getImageSizeFromTileSize(breakpoint, tileSize);
    const image = imageFormatter.resize(imageSize.width, imageSize.height, article.imageCropModel);
    return (React.createElement("a", { className: `${baseClassName} -${tileSize} background-primary -aspectRatio${TileAspectRatio[imageSize.aspectRatio]} ${className}`, href: article.link, style: { backgroundImage: `url(${image})` } },
        React.createElement("div", { className: `${baseClassName}__wrapper` },
            React.createElement(Rubric, { className: `${baseClassName}__rubric` }, article.tag),
            React.createElement(Title, { className: `${baseClassName}__title` }, article.title),
            React.createElement("div", { className: `${baseClassName}__button` },
                React.createElement(CircularButton, { color: ButtonColor.Primary, inverted: true, useStroke: true })))));
};
function getImageSizeFromTileSize(breakpoint, tileSize) {
    const baseWidth = ContainerSizeMap[breakpoint];
    let aspectRatio;
    const proportions = {
        height: 1,
        width: 1,
    };
    switch (tileSize) {
        case TileSize.DesktopSmall:
            aspectRatio = TileAspectRatio.Desktop;
            proportions.height = 1 / 3;
            proportions.width = 1 / 3;
            break;
        case TileSize.DesktopBig:
            aspectRatio = TileAspectRatio.Desktop;
            proportions.height = 2 / 3;
            proportions.width = 2 / 3;
            break;
        case TileSize.DesktopTall:
            aspectRatio = TileAspectRatio.Desktop;
            proportions.height = 2 / 3;
            proportions.width = 1 / 3;
            break;
        case TileSize.TabletNormal:
            aspectRatio = TileAspectRatio.Desktop;
            proportions.height = 1 / 2;
            proportions.width = 1 / 2;
            break;
        case TileSize.TabletWide:
            aspectRatio = TileAspectRatio.Desktop;
            proportions.height = 1 / 2;
            proportions.width = 1;
            break;
        case TileSize.Mobile:
        default:
            aspectRatio = TileAspectRatio.Mobile;
            proportions.height = 1;
            proportions.width = 1;
            break;
    }
    return {
        aspectRatio,
        height: (baseWidth / aspectRatio) * proportions.height,
        width: baseWidth * proportions.width,
    };
}
export default Tile;
export { TileSize };
