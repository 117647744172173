import React from 'react';
import ReactDOM from 'react-dom';
import Search from './templates/organisms/search';
document.addEventListener('DOMContentLoaded', () => {
    const searches = document.querySelectorAll('.plf-search-wrapper');
    for (const search of searches) {
        const searchString = search.dataset.querystring;
        const totalCount = search.dataset.totalcount;
        const searchItems = JSON.parse(search.dataset.documents);
        const loadUrl = search.dataset.loadurl;
        ReactDOM.render(React.createElement(Search, { searchstring: searchString, initialSearchItems: searchItems, totalcount: totalCount, loadUrl: loadUrl }), search);
    }
});
