import React from 'react';
import ReactDOM from 'react-dom';
import BlogDetail from './templates/organisms/blogDetail';
document.addEventListener('DOMContentLoaded', () => {
    const detailWidgets = document.querySelectorAll('.plf-blog-detail');
    for (const detailWidget of detailWidgets) {
        const blogArticle = JSON.parse(detailWidget.dataset.blogArticle);
        const likeUrl = detailWidget.dataset.likeUrl;
        const id = detailWidget.dataset.id;
        ReactDOM.render(React.createElement(BlogDetail, { blogArticle: blogArticle, id: id, likeUrl: likeUrl }), detailWidget);
    }
});
