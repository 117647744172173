var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useMemo, useRef } from 'react';
import './index.scss';
import RadioInputGroup from '../../../../../../../../design/1/js/templates/molecules/radioInputGroup';
import TextInput from '../../../../../../../../design/1/js/templates/atoms/textInput';
import TextareaInput from '../../../../../../../../design/1/js/templates/atoms/textareaInput';
import FileInput from '../../../../../../../../design/1/js/templates/atoms/fileInput';
import Form from '../form';
import FileHelper from '../../../lib/FileHelper';
const initialFormData = Object.freeze({
    gender: '',
    firstname: '',
    lastname: '',
    company: '',
    email: '',
    phone: '',
    message: '',
    file: {
        file: '',
        fileName: '',
        fileType: '',
    },
});
const ContactForm = ({ actionUrl, recaptchaSiteKey }) => {
    const baseClassName = 'w-contact-form';
    const fileHelper = useMemo(() => {
        const maxFileSize = 100 * 1024 * 1024; // 100 MB
        const allowedFileTypes = ['jpg', 'pdf'];
        return new FileHelper(maxFileSize, allowedFileTypes);
    }, []);
    const customFileError = useRef();
    const handleValidate = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const errors = {};
        if (values.firstname === '') {
            errors.firstname = 'Bitte Vornamen eingeben.';
        }
        if (values.lastname === '') {
            errors.lastname = 'Bitte Nachname eingeben.';
        }
        if (values.email === '') {
            errors.email = 'Bitte E-Mail eingeben.';
        }
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Bitte gültige E-Mail eingeben.';
        }
        return errors;
    });
    return (React.createElement(Form, { actionUrl: actionUrl, handleValidate: handleValidate, initialValues: initialFormData, fileFields: ['file'], name: "Kontaktformular", recaptchaKey: recaptchaSiteKey, teaserTitle: "", teaserContent: "" }, (formik => {
        var _a, _b;
        const handleFileChange = event => {
            fileHelper.getFileContent(event.target)
                .then(([file]) => {
                formik.setFieldValue('file', file);
                customFileError.current = undefined;
            })
                .catch((error) => {
                formik.setFieldError('file', error.message);
                customFileError.current = error.message;
            })
                .finally(() => formik.setFieldTouched('file'));
        };
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: `${baseClassName}__genderWrapper` },
                React.createElement("p", { className: `${baseClassName}__requiredInfo` }, "* Pflichtfelder"),
                React.createElement(RadioInputGroup, { name: "gender", onChange: formik.handleChange, radioInputs: [
                        {
                            id: 'female',
                            label: 'Frau',
                        },
                        {
                            id: 'male',
                            label: 'Herr',
                        },
                        {
                            id: 'neutral',
                            label: 'Neutral',
                        },
                    ] })),
            React.createElement(TextInput, { errorMessage: formik.touched.firstname && formik.errors.firstname ? formik.errors.firstname.toString() : '', id: "firstname", label: "Vorname", onBlur: formik.handleBlur, onChange: formik.handleChange, required: true, type: "text", value: formik.values.firstname }),
            React.createElement(TextInput, { errorMessage: formik.touched.lastname && formik.errors.lastname ? formik.errors.lastname.toString() : '', id: "lastname", label: "Nachname", onBlur: formik.handleBlur, onChange: formik.handleChange, required: true, type: "text", value: formik.values.lastname }),
            React.createElement(TextInput, { id: "company", label: "Firma", onBlur: formik.handleBlur, onChange: formik.handleChange, required: false, type: "text", value: formik.values.company }),
            React.createElement(TextInput, { errorMessage: formik.touched.email && formik.errors.email ? formik.errors.email.toString() : '', id: "email", label: "E-Mail", onBlur: formik.handleBlur, onChange: formik.handleChange, required: true, type: "text", value: formik.values.email }),
            React.createElement(TextInput, { id: "phone", label: "Telefon", onBlur: formik.handleBlur, onChange: formik.handleChange, required: false, type: "text", value: formik.values.phone }),
            React.createElement(TextareaInput, { id: "message", label: "Mitteilung", onBlur: formik.handleBlur, onChange: formik.handleChange, value: formik.values.message }),
            React.createElement(FileInput, { accept: "application/pdf, image/jpeg", errorMessage: formik.touched.file && ((_b = (_a = customFileError.current) !== null && _a !== void 0 ? _a : formik.errors.file) !== null && _b !== void 0 ? _b : ''), fileName: formik.values.file.fileName, onChange: handleFileChange, id: "file", label: "Upload (PDF, JPG, max. 100 MB)" })));
    })));
};
export default ContactForm;
