import React from 'react';
import ReactDOM from 'react-dom';
import JobDetail from './templates/organisms/jobDetail';
document.addEventListener('DOMContentLoaded', () => {
    const detailWidgets = document.querySelectorAll('.plf-job-detail');
    detailWidgets.forEach(detailWidget => {
        const jobAdvert = JSON.parse(detailWidget.dataset.jobAdvert);
        ReactDOM.render(React.createElement(JobDetail, { jobAdvert: jobAdvert }), detailWidget);
    });
});
