import React, { useEffect, useRef } from 'react';
import './index.scss';
import FlyoutSitemap from '../../molecules/flyoutSitemap';
import Icon, { IconName } from '../../atoms/icon';
import useBodyScroll from '../../../lib/hooks/useBodyScroll';
import SearchInput from '../../molecules/searchInput';
const Flyout = ({ className = '', menu, onClose, searchPage, visible, }) => {
    const baseClassname = 'o-flyout';
    const navElement = useRef();
    useBodyScroll(!visible);
    useEffect(() => {
        if (!visible) {
            return;
        }
        navElement.current.scrollTop = 0;
    }, [visible]);
    return (React.createElement("nav", { ref: navElement, className: `${baseClassname} ${visible ? '-open' : ''} background-primary ${className}` },
        React.createElement("div", { className: `${baseClassname}__wrapper` },
            React.createElement("div", { className: `${baseClassname}__content container` },
                React.createElement(FlyoutSitemap, { className: `${baseClassname}__sitemap`, sitemap: menu.sitemap }),
                React.createElement(SearchInput, { autofocus: true, className: `${baseClassname}__search header3`, isInputVisible: true, searchPage: searchPage, setInputVisible: () => true }),
                React.createElement("div", { className: `${baseClassname}__closeButton`, onClick: onClose },
                    React.createElement(Icon, { name: IconName.Close, useStroke: true }))))));
};
export default Flyout;
