import ImageWrapperTransformer from './ImageWrapperTransformer';
import ContentTransformer from './ContentTransformer';
export default class BlurResizeSwiperEffect {
    constructor(selectorBase) {
        this.animationFrameOperations = [];
        this.setTransition = (swiper, duration) => {
            this.currentTransitionDuration = duration;
        };
        this.setTranslate = (swiper) => {
            const transitionDuration = this.getTransitionDuration();
            if (this.animationFramePending) {
                if (transitionDuration === 0) {
                    return;
                }
                this.animationFrameOperations = [];
                this.animationFramePending = false;
            }
            const { activeIndex, slides, $wrapperEl } = swiper;
            const indexDifference = slides.eq(activeIndex)[0].progress;
            slides.each((slide) => {
                this.animateSlide(slide, transitionDuration, indexDifference, () => {
                    $wrapperEl.trigger('transitionend');
                });
            });
            if (this.animationFramePending) {
                requestAnimationFrame(() => {
                    this.animationFrameOperations.forEach(operation => operation());
                    this.animationFrameOperations = [];
                    this.animationFramePending = false;
                });
            }
        };
        this.selectorBase = selectorBase;
        this.currentTransitionDuration = 0;
    }
    getTransitionDuration() {
        const transitionSpeed = this.currentTransitionDuration;
        // Reset the variable for future calls
        this.currentTransitionDuration = 0;
        return transitionSpeed;
    }
    animateSlide(slide, transitionDuration, indexDifference, animationEndCallback) {
        const progress = slide.progress;
        const offset = slide.swiperSlideOffset;
        const isSlideActive = Math.abs(progress) < 0.5;
        const isSlideVisible = Math.abs(progress) <= 2 || isSlideActive || Math.abs(indexDifference) > 1;
        slide.style.visibility = isSlideVisible ? 'visible' : 'hidden';
        this.animationFramePending = true;
        this.animationFrameOperations.push(() => {
            slide.style.transform = `translateX(${-offset}px)`;
            slide.style.width = '100%';
            const callback = isSlideActive ? animationEndCallback : undefined;
            this.applyTransformationsToSlide(slide, transitionDuration, progress, callback);
        });
    }
    applyTransformationsToSlide(slide, transitionDuration, progress, animationEndCallback) {
        const imageWrapper = slide.querySelector(`${this.selectorBase}__imageWrapper`);
        const image = slide.querySelector(`${this.selectorBase}__image.-crisp`);
        const content = slide.querySelector(`${this.selectorBase}__content`);
        const customerName = slide.querySelector(`${this.selectorBase}__customer`);
        const imageWrapperTransform = ImageWrapperTransformer.getTransform(progress);
        const imageOpacity = String(ImageWrapperTransformer.getCrispImageOpacity(progress));
        const contentOpacity = String(ContentTransformer.getOpacity(progress));
        if (transitionDuration === 0) {
            imageWrapper.style.transform = imageWrapperTransform;
            image.style.opacity = imageOpacity;
            customerName.style.opacity = imageOpacity;
            content.style.opacity = contentOpacity;
            return;
        }
        const imageWrapperAnimation = imageWrapper.animate({
            transform: imageWrapperTransform,
        }, {
            duration: transitionDuration,
        });
        imageWrapperAnimation.onfinish = () => { imageWrapper.style.transform = imageWrapperTransform; };
        const imageAnimation = image.animate({
            opacity: imageOpacity,
        }, {
            duration: transitionDuration,
        });
        imageAnimation.onfinish = () => { image.style.opacity = imageOpacity; };
        const customerNameAnimation = customerName.animate({
            opacity: imageOpacity,
        }, {
            duration: transitionDuration,
        });
        customerNameAnimation.onfinish = () => { customerName.style.opacity = imageOpacity; };
        const contentAnimation = content.animate({
            opacity: contentOpacity,
        }, {
            duration: transitionDuration,
        });
        contentAnimation.onfinish = () => { content.style.opacity = contentOpacity; };
        if (animationEndCallback !== undefined) {
            getAnimationsPromise([
                imageWrapperAnimation,
                imageAnimation,
                customerNameAnimation,
                contentAnimation,
            ]).then(() => {
                animationEndCallback();
            });
        }
    }
}
function getAnimationsPromise(animations) {
    const animationsPromises = animations.map(animation => new Promise(resolve => {
        const oldFinish = animation.onfinish;
        animation.onfinish = event => {
            oldFinish === null || oldFinish === void 0 ? void 0 : oldFinish.call(animation, event);
            resolve(event);
        };
    }));
    return Promise.all(animationsPromises);
}
