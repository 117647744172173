import React from 'react';
import ReactDOM from 'react-dom';
import Contactlinks from './templates/molecules/contactlinks';
document.addEventListener('DOMContentLoaded', () => {
    const linkboxes = document.querySelectorAll('.plf-contactlinks-wrapper');
    linkboxes.forEach(contactlinks => {
        const props = JSON.parse(contactlinks.dataset.contactlinksProperties);
        ReactDOM.render(React.createElement(Contactlinks, { title: props.title, links: props.links }), contactlinks);
    });
});
