import React from 'react';
import Image from '../../atoms/image';
import Text from '../text';
import './index.scss';
const Story = ({ className = '', mirrored = false, parallaxStory }) => {
    const baseClassName = 'w-parallax-story';
    return (React.createElement("div", { className: `${baseClassName} ${className} ${mirrored ? '-mirrored' : ''}` },
        React.createElement(Text, { className: `${baseClassName}__text`, parallaxStory: parallaxStory }),
        React.createElement("a", { className: `${baseClassName}__imagelink`, href: parallaxStory.link },
            React.createElement(Image, { className: `${baseClassName}__image`, parallaxStory: parallaxStory }))));
};
export default Story;
