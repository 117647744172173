import React from 'react';
import ImageResizer, { cropModelToBackgroundPosition } from '../../../../../../../../design/1/js/lib/ImageResizer';
import CircularButton from '../../../../../../../../design/1/js/templates/atoms/circularButton';
import { ButtonColor } from '../../../../../../../../design/1/js/templates/atoms/textButton';
import './index.scss';
import OverflowText from '../../../../../../../../design/1/js/templates/atoms/overflowText';
const tileRatio = 4 / 3;
const ReferenceTile = ({ className = '', reference, tileWidth }) => {
    const baseClassName = 'w-referenceGallery-referenceTile';
    const imageResizer = new ImageResizer(reference.image.image);
    const backgroundUrl = imageResizer.resize(tileWidth, tileWidth / tileRatio, reference.image.cropModel);
    return (React.createElement("a", { className: `${baseClassName} ${className} background-dark`, href: reference.url, style: {
            backgroundImage: `url(${backgroundUrl})`,
            backgroundPosition: cropModelToBackgroundPosition(reference.image.cropModel),
        } },
        React.createElement("div", { className: `${baseClassName}__wrapper` },
            React.createElement(OverflowText, { className: `${baseClassName}__rubric rubric2`, lines: 2 }, reference.client),
            React.createElement(OverflowText, { className: `${baseClassName}__title header2`, lines: 3 }, reference.name),
            React.createElement(CircularButton, { className: `${baseClassName}__arrow`, color: ButtonColor.Primary, useStroke: true }))));
};
export default ReferenceTile;
