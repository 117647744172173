import React from 'react';
import './index.scss';
import FooterSitemap from '../../molecules/footerSitemap';
import FooterEmblems from '../../molecules/footerEmblems';
import FooterContact from '../../molecules/footerContact';
import SearchInput from '../../molecules/searchInput';
const Footer = ({ className = '', menu, searchPage }) => {
    const baseClassName = 'o-footer';
    return (React.createElement("footer", { className: `${baseClassName} ${className}` },
        React.createElement("div", { className: `${baseClassName}__contentWrapper container` },
            React.createElement(SearchInput, { className: `${baseClassName}__search`, isInputVisible: true, searchPage: searchPage, setInputVisible: () => { }, useStrokeWhenActive: false }),
            React.createElement(FooterContact, { className: `${baseClassName}__contact`, menu: menu }),
            React.createElement(FooterSitemap, { className: `${baseClassName}__sitemap`, menu: menu }),
            React.createElement(FooterEmblems, { className: `${baseClassName}__emblems` }))));
};
export default Footer;
