import React from 'react';
import ReactDOM from 'react-dom';
import Header from '../templates/organisms/header';
import Footer from '../templates/organisms/footer';
import BreadcrumbMenu from '../templates/molecules/breadcrumbMenu';
export default function initializeLayout() {
    document.addEventListener('DOMContentLoaded', () => {
        initializeHeader();
        initializeFooter();
        initializeBreadcrumb();
    });
}
function initializeHeader() {
    const headers = document.querySelectorAll('.plf-header');
    headers.forEach(header => {
        const contactPage = header.dataset.contactPage;
        const searchPage = header.dataset.searchPage;
        const menu = {
            sitemap: JSON.parse(header.dataset.menuEntries),
            links: {
                imprint: header.dataset.menuImprint,
                privacy: header.dataset.menuPrivacy,
            },
        };
        ReactDOM.render(React.createElement(Header, { contactPage: contactPage, menu: menu, searchPage: searchPage }), header);
    });
}
function initializeFooter() {
    const footers = document.querySelectorAll('.plf-footer');
    footers.forEach(footer => {
        const menu = {
            sitemap: footer.dataset.menuSitemap,
            links: {
                imprint: footer.dataset.menuImprint,
                privacy: footer.dataset.menuPrivacy,
            },
        };
        const searchPage = footer.dataset.searchPage;
        ReactDOM.render(React.createElement(Footer, { menu: menu, searchPage: searchPage }), footer);
    });
}
function initializeBreadcrumb() {
    const breadcrumb = document.querySelector('[data-breadcrumb-navigation]');
    if (breadcrumb === null) {
        return;
    }
    const breadcrumbItems = JSON.parse(breadcrumb.dataset.breadcrumbNavigation);
    if (breadcrumbItems.length === 0) {
        return;
    }
    ReactDOM.render(React.createElement(BreadcrumbMenu, { breadcrumbItems: breadcrumbItems }), breadcrumb);
}
