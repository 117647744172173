import React, { useEffect, useRef, useState } from 'react';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import CurrentProjectButton from '../../atoms/currentProjectButton';
import ContentGroup from '../../molecules/contentGroup';
import Indicators from '../../molecules/indicators';
import BlurResizeSwiperEffect from '../../../lib/SwiperEffect/BlurResizeSwiperEffect';
import './index.scss';
import CarouselButton, { ButtonDirection } from '../../atoms/carouselButton';
import { getClosestIncrease, nextIndex, previousIndex } from '../../../lib/CircularArrayIndex';
import ImageWrapper from '../../molecules/imageWrapper';
import SwiperHelper from '../../../lib/SwiperHelper';
SwiperCore.use([Autoplay]);
const slideManualSpeed = 300; // ms
const DesktopView = ({ className = '', index, onSelectIndex, references, }) => {
    const baseClassName = 'w-referenceCarousel-desktopView';
    const [swiper, setSwiper] = useState(null);
    const isIndexChangeAllowed = useRef(true);
    const isArrowAction = useRef(false);
    const isDuringAutoplay = useRef(false);
    const onSlideChange = (swiperInstance) => {
        if (isIndexChangeAllowed.current) {
            fixIndex(swiperInstance);
            onSelectIndex(swiperInstance.realIndex);
        }
    };
    const onClickPrev = () => {
        if (isIndexChangeAllowed.current) {
            isArrowAction.current = true;
            onSelectIndex(previousIndex(index, references.length));
        }
    };
    const onClickNext = () => {
        if (isIndexChangeAllowed.current) {
            isArrowAction.current = true;
            onSelectIndex(nextIndex(index, references.length));
        }
    };
    const fixIndex = (swiperInstance) => {
        const wasAutoplay = isDuringAutoplay.current;
        isIndexChangeAllowed.current = false;
        SwiperHelper.fixLoopIndex(swiperInstance);
        isIndexChangeAllowed.current = true;
        if (wasAutoplay) {
            swiperInstance.autoplay.start();
        }
    };
    useEffect(() => {
        if (swiper === null || index === swiper.realIndex) {
            return;
        }
        if (isArrowAction.current) {
            isArrowAction.current = false;
            const closestChange = getClosestIncrease(swiper.realIndex, index, references.length);
            if (closestChange >= -1 && closestChange < 0) {
                swiper.slidePrev(slideManualSpeed);
                return;
            }
            if (closestChange > 0 && closestChange <= 1) {
                swiper.slideNext(slideManualSpeed);
                return;
            }
        }
        swiper.slideToLoop(index, slideManualSpeed);
    }, [index, swiper]);
    const desktopEffect = new BlurResizeSwiperEffect(`.${baseClassName}`);
    return (React.createElement("div", { className: `${baseClassName} ${className}` },
        React.createElement(Swiper, { allowTouchMove: false, autoplay: {
                delay: 10000,
            }, initialSlide: index, loop: true, loopedSlides: Math.max(2, references.length), onAutoplayStart: () => { isDuringAutoplay.current = true; }, onAutoplayStop: () => { isDuringAutoplay.current = false; }, onSetTransition: desktopEffect.setTransition, onSetTranslate: desktopEffect.setTranslate, onSwiper: setSwiper, onTransitionEnd: onSlideChange, speed: 1000, virtualTranslate: true, watchSlidesProgress: true },
            references.map((reference, referenceIndex) => {
                const key = `${referenceIndex}`;
                return (React.createElement(SwiperSlide, { key: key, className: `${baseClassName}__slide` },
                    React.createElement(ImageWrapper, { className: `${baseClassName}__imageWrapper`, classNameImage: `${baseClassName}__image`, classNameCustomer: `${baseClassName}__customer`, reference: reference }),
                    React.createElement(ContentGroup, { className: `${baseClassName}__content swiper-no-swiping`, reference: reference })));
            }),
            React.createElement("span", { slot: "container-end" },
                React.createElement("div", { className: `${baseClassName}__imageOverlay` },
                    React.createElement(CurrentProjectButton, { className: `${baseClassName}__currentProjectButton`, url: references[index].link }),
                    React.createElement("div", { className: `${baseClassName}__carouselButton -prev swiper-no-swiping`, onClick: onClickPrev, role: "button" },
                        React.createElement(CarouselButton, { className: `${baseClassName}__carouselButtonIcon`, direction: ButtonDirection.Left })),
                    React.createElement("div", { className: `${baseClassName}__carouselButton -next swiper-no-swiping`, onClick: onClickNext, role: "button" },
                        React.createElement(CarouselButton, { className: `${baseClassName}__carouselButtonIcon`, direction: ButtonDirection.Right }))))),
        React.createElement(Indicators, { length: references.length, selectedIndex: index, onSelect: onSelectIndex })));
};
export default DesktopView;
