import React from 'react';
const ProjectFacts = ({ className = '', reference }) => {
    const baseClassName = 'w-referencedetail-projectFacts';
    const monthFormatter = getMonthFormatter();
    return (React.createElement("ul", { className: `${baseClassName} ${className}` },
        reference.facts.technologies.length > 0 && (React.createElement("li", null,
            'Umgesetzt mit ',
            concatenateItems(reference.facts.technologies))),
        reference.facts.goals && (React.createElement("li", null, reference.facts.goals)),
        reference.facts.start && (React.createElement("li", null,
            'Projektstart: ',
            monthFormatter.format(new Date(reference.facts.start)))),
        reference.facts.end && (React.createElement("li", null,
            'Projektende: ',
            monthFormatter.format(new Date(reference.facts.end)))),
        reference.facts.live && (React.createElement("li", null,
            'GoLive: ',
            monthFormatter.format(new Date(reference.facts.live)))),
        reference.facts.departments.length > 0 && (React.createElement("li", null,
            'Involvierte Abteilungen: ',
            concatenateItems(reference.facts.departments)))));
};
function getMonthFormatter() {
    return new Intl.DateTimeFormat('de', {
        month: 'short',
        year: 'numeric',
    });
}
function concatenateItems(items) {
    if (items.length > 1) {
        return `${items.slice(0, -1).join(', ')} und ${items[items.length - 1]}`;
    }
    return items.join();
}
export default ProjectFacts;
