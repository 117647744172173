import React from 'react';
import ReactDOM from 'react-dom';
import JobAdverts from './templates/organisms/jobAdverts';
document.addEventListener('DOMContentLoaded', () => {
    const jobAdverts = document.querySelectorAll('.plf-job');
    jobAdverts.forEach(jobAdvert => {
        const jobsList = JSON.parse(jobAdvert.dataset.jobs);
        ReactDOM.render(React.createElement(JobAdverts, { jobs: jobsList }), jobAdvert);
    });
});
