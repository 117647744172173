import Breakpoint from './Breakpoint';
const ContainerSizeMap = {
    [Breakpoint.XSmall]: 576,
    [Breakpoint.Small]: 768,
    [Breakpoint.Medium]: 992,
    [Breakpoint.Large]: 1200,
    [Breakpoint.XLarge]: 1400,
    [Breakpoint.XXLarge]: 1800,
};
export default ContainerSizeMap;
