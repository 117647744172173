import React from 'react';
import ReactDOM from 'react-dom';
import InnerHTML from 'dangerously-set-html-content';
document.addEventListener('DOMContentLoaded', () => {
    const contentWidgets = document.querySelectorAll('.plf-content');
    contentWidgets.forEach(contentWidget => {
        const content = contentWidget.dataset.content;
        ReactDOM.render(React.createElement(InnerHTML, { html: content }), contentWidget);
    });
});
