// @ts-ignore TS2307: Cannot find module
import snowflakeTexture from './snowflake.png';
// @ts-ignore TS2307: Cannot find module
import snowVertex from './snow.vert';
import ShaderProgram from './ShaderProgram';
// @ts-ignore TS2307: Cannot find module
import snowFragment from './snow.frag';
export default class SnowShader {
    constructor(element) {
        this.wind = {
            current: 0,
            force: 0.1,
            max: 0.125,
            easing: 0.005,
        };
        this.count = 2500;
        this.hovering = false;
        this.element = element;
    }
    initialize() {
        const count = this.count;
        const wind = this.wind;
        const snowShader = this;
        // eslint-disable-next-line no-new
        new ShaderProgram(this.element, {
            depthTest: false,
            texture: snowflakeTexture,
            mousemove: true,
            uniforms: {
                worldSize: { type: 'vec3', value: [0, 0, 0] },
                gravity: { type: 'float', value: 100 },
                wind: { type: 'float', value: 0 },
            },
            buffers: {
                size: { size: 1, data: [] },
                rotation: { size: 3, data: [] },
                speed: { size: 3, data: [] },
            },
            vertex: snowVertex,
            fragment: snowFragment,
            onResize(w, h, dpi) {
                const position = [];
                const color = [];
                const size = [];
                const rotation = [];
                const speed = [];
                // z in range from -80 to 80, camera distance is 100
                // max height at z of -80 is 110
                const height = 110;
                const width = (w / h) * height;
                const depth = 80;
                const particles = (w / h) * count;
                for (let i = 0; i < particles; i++) {
                    position.push(-width + Math.random() * width * 2, -height + Math.random() * height * 2, Math.random() * depth * 2);
                    speed.push(// 0, 0, 0 )
                    1 + Math.random(), 1 + Math.random(), Math.random() * 10); // x, y, sinusoid
                    rotation.push(Math.random() * 2 * Math.PI, Math.random() * 20, Math.random() * 10); // angle, speed, sinusoid
                    color.push(1, 1, 1, 0.1 + Math.random() * 0.2);
                    size.push(5 * Math.random() * 5 * ((h * dpi) / 1000));
                }
                this.uniforms.worldSize = [width, height, depth];
                this.buffers.position = position;
                this.buffers.color = color;
                this.buffers.rotation = rotation;
                this.buffers.size = size;
                this.buffers.speed = speed;
            },
            onUpdate(delta) {
                const target = snowShader.hovering ? this.uniforms.mousemove[0] : wind.force;
                wind.force += (target - wind.force) * wind.easing;
                wind.force = Math.max(-wind.max, Math.min(wind.force, wind.max));
                wind.current += wind.force * (delta * 0.2);
                this.uniforms.wind = wind.current;
            },
        });
        this.initializeEvents();
    }
    initializeEvents() {
        window.addEventListener('mousemove', event => {
            const bounds = this.element.getBoundingClientRect();
            this.hovering = event.clientX >= bounds.x && event.clientX <= bounds.right && event.clientY >= bounds.y && event.clientY <= bounds.bottom;
        });
    }
}
