import React from 'react';
import Title from '../../atoms/title';
import Lead from '../../atoms/lead';
const ContentGroup = ({ className = '', reference }) => {
    const baseClassName = 'w-referenceCarousel-contentGroup';
    return (React.createElement("div", { className: `${baseClassName} ${className}` },
        React.createElement(Title, { className: `${baseClassName}__title`, title: reference.title }),
        React.createElement(Lead, { className: `${baseClassName}__lead`, lead: reference.lead })));
};
export default ContentGroup;
