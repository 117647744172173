import React from 'react';
import { useFormik } from 'formik';
import Filter from '../../../../../../../../design/1/js/templates/organisms/filter';
import SelectInput from '../../../../../../../../design/1/js/templates/molecules/selectInput';
import RadioInputGroup from '../../../../../../../../design/1/js/templates/molecules/radioInputGroup';
import './index.scss';
import FilterOptions from '../../../lib/FilterOptions';
const ReferenceFilter = ({ branches, className = '', filterCallback, products, }) => {
    const baseClassName = 'w-referenceGallery-referenceFilter';
    const branchesMap = new Map(branches.map(branch => [branch.id.toString(), branch.name]));
    const productsMap = new Map(products.map(product => [product.id.toString(), product.name]));
    const formik = useFormik({
        initialValues: {
            mode: 'all',
            branch: null,
            product: null,
        },
        onReset: () => {
            filterCallback(new FilterOptions(null, null));
        },
        onSubmit: values => {
            var _a, _b;
            const branchFilter = (_a = branches.find(branch => branch.id === Number.parseInt(values.branch, 10))) !== null && _a !== void 0 ? _a : null;
            const productFilter = (_b = products.find(product => product.id === Number.parseInt(values.product, 10))) !== null && _b !== void 0 ? _b : null;
            filterCallback(new FilterOptions(branchFilter, productFilter));
            formik.setSubmitting(false);
        },
        validate: values => {
            const errors = {};
            if (values.mode === 'branch' && values.branch === null) {
                errors.branch = 'Branch auswählen';
            }
            if (values.mode === 'product' && values.product === null) {
                errors.product = 'Produkt auswählen';
            }
            return errors;
        },
    });
    if (formik.values.mode !== 'branch') {
        formik.values.branch = null;
    }
    if (formik.values.mode !== 'product') {
        formik.values.product = null;
    }
    return (React.createElement("div", { className: `${baseClassName} ${className}` },
        React.createElement(Filter, { formik: formik },
            React.createElement(RadioInputGroup, { className: `${baseClassName}__filterInput`, name: "mode", radioInputs: [
                    {
                        id: 'all',
                        label: 'Alles anzeigen',
                    },
                    {
                        id: 'product',
                        label: 'Nach Produkt',
                    },
                    {
                        id: 'branch',
                        label: 'Nach Branche',
                    },
                ], onChange: event => {
                    formik.handleChange(event);
                    // Timeout fixes stale values, see https://github.com/formium/formik/issues/2083
                    setTimeout(() => formik.setFieldTouched('mode'));
                }, value: formik.values.mode }),
            formik.values.mode === 'branch' && (React.createElement(SelectInput, { className: `${baseClassName}__filterInput`, id: "branch", onBlur: formik.handleBlur, onChange: formik.handleChange, options: branchesMap, placeholder: "Branche ausw\u00E4hlen", value: formik.values.branch })),
            formik.values.mode === 'product' && (React.createElement(SelectInput, { className: `${baseClassName}__filterInput`, id: "product", onBlur: formik.handleBlur, onChange: formik.handleChange, options: productsMap, placeholder: "Produkt ausw\u00E4hlen", value: formik.values.product })))));
};
export default ReferenceFilter;
