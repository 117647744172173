import React, { useRef } from 'react';
import ImageResizer, { CropModel, cropModelToBackgroundPosition, } from '../../../../../../../../design/1/js/lib/ImageResizer';
import './index.scss';
import useElementDimensions from '../../../../../../../../design/1/js/lib/hooks/useElementDimensions';
const Image = ({ className = '', parallaxStory, }) => {
    const baseClassName = 'w-parallax-image';
    const imageWrapperRef = useRef(null);
    const imageDimensions = useElementDimensions(imageWrapperRef);
    const imageResizer = new ImageResizer(parallaxStory.image);
    const imageCropModel = parallaxStory.imageCropModel || CropModel.CenterCenter;
    let backgroundUrl = null;
    if (imageDimensions !== null) {
        backgroundUrl = imageResizer.resize(imageDimensions.width, imageDimensions.height, imageCropModel);
    }
    return (React.createElement("div", { ref: imageWrapperRef, className: `${baseClassName} ${className}` },
        React.createElement("div", { className: `${baseClassName}__image`, style: {
                backgroundImage: `url(${backgroundUrl})`,
                backgroundPosition: cropModelToBackgroundPosition(imageCropModel),
            } })));
};
export default Image;
