import React from 'react';
const Filter = () => (React.createElement("svg", { width: "59.304", height: "45.996", viewBox: "0 0 59.304 45.996", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { fill: "none", stroke: "currentColor", strokeWidth: "2", strokeLinejoin: "bevel" },
        React.createElement("line", { y1: "45.993999", x1: "29.652", x2: "29.652", y2: "38.348999" }),
        React.createElement("line", { y1: "24.175999", x1: "29.652", x2: "29.652", y2: "1.994" }),
        React.createElement("line", { y1: "9.6389999", x1: "10.086", x2: "10.086", y2: "1.994" }),
        React.createElement("line", { y1: "45.993999", x1: "10.086", x2: "10.086", y2: "23.812" }),
        React.createElement("line", { y1: "45.996002", x1: "49.216999", x2: "49.216999", y2: "17.174" }),
        React.createElement("circle", { cx: "10.087", cy: "16.726999", r: "7.0869999" }),
        React.createElement("circle", { cx: "29.650999", cy: "31.263", r: "7.0869999" }),
        React.createElement("circle", { cx: "49.217999", cy: "10.087", r: "7.0869999" }))));
export default Filter;
