import React from 'react';
import { useFormik } from 'formik';
import FilterOptions from '../../../lib/FilterOptions';
import Filter from '../../../../../../../../design/1/js/templates/organisms/filter';
import SelectInput from '../../../../../../../../design/1/js/templates/molecules/selectInput';
import TextButton, { ButtonColor, TextButtonSize, } from '../../../../../../../../design/1/js/templates/atoms/textButton';
import './index.scss';
const GalleryFilter = ({ className = '', filterCallback, themes }) => {
    const baseClassName = 'w-blog-galleryFilter';
    const themesMap = new Map(themes.map(theme => [theme.id.toString(), theme.name]));
    const formik = useFormik({
        initialValues: {
            theme: null,
        },
        onReset: () => {
            filterCallback(new FilterOptions(null));
        },
        onSubmit: values => {
            var _a;
            const themeFilter = (_a = themes.find(theme => theme.id === Number.parseInt(values.theme, 10))) !== null && _a !== void 0 ? _a : null;
            filterCallback(new FilterOptions(themeFilter));
            formik.setSubmitting(false);
        },
    });
    return (React.createElement("div", { className: `${baseClassName} ${className}` },
        React.createElement(Filter, { formik: formik },
            React.createElement(SelectInput, { className: `${baseClassName}__filterInput`, id: "theme", onBlur: formik.handleBlur, onChange: formik.handleChange, options: themesMap, placeholder: "Themenbereich ausw\u00E4hlen", value: formik.values.theme }),
            formik.dirty && formik.isValid && (React.createElement("div", { className: `${baseClassName}__resetButtonWrapper` },
                React.createElement(TextButton, { className: `${baseClassName}__resetButton`, color: ButtonColor.GrayDark, handleClick: () => formik.resetForm(), size: TextButtonSize.Normal }, "Filter zur\u00FCcksetzen"))))));
};
export default GalleryFilter;
