import React, { useState } from 'react';
import useBreakpoint from '../../../lib/hooks/useBreakpoint';
import Breakpoint from '../../../lib/types/Breakpoint';
import FilterMobile from '../filterMobile';
import FilterDesktop from '../filterDesktop';
const Filter = ({ children, className = '', formik }) => {
    const baseClassName = 'o-filter';
    const [isOpen, setOpen] = useState(false);
    const breakpoint = useBreakpoint();
    const isMobile = breakpoint <= Breakpoint.Small;
    return (React.createElement("div", { className: `${baseClassName} ${className}` }, isMobile ? (React.createElement(FilterMobile, { formik: formik, isOpen: isOpen, setOpen: setOpen }, children)) : (React.createElement(FilterDesktop, { formik: formik, isOpen: isOpen, setOpen: setOpen }, children))));
};
export default Filter;
