import React from 'react';
import ContactLayout from '../../../../../../../../design/1/js/templates/molecules/contactLayout';
import DecryptMailAnchor from '../../../../../../../../design/1/js/templates/atoms/decryptMailAnchor';
const Contact = ({ contact }) => {
    const baseClassName = 'w-team-contact';
    const firstname = contact.name.split(' ')[0];
    return (React.createElement("div", { className: baseClassName },
        React.createElement(ContactLayout, { image: contact.image, imageCropModel: contact.imageCropModel, header: contact.text, rubric: "Interessiert?" },
            contact.phone.length !== 0 && React.createElement("a", { href: `tel:${contact.phone}` }, contact.phone),
            React.createElement("br", null),
            React.createElement(DecryptMailAnchor, { email: contact.mail }, `E-Mail an ${firstname} schreiben`))));
};
export default Contact;
