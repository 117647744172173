import React from 'react';
import useBreakpoint from '../../../../../../../../design/1/js/lib/hooks/useBreakpoint';
import ImageResizer, { cropModelToBackgroundPosition } from '../../../../../../../../design/1/js/lib/ImageResizer';
import ContainerSizeMap from '../../../../../../../../design/1/js/lib/types/ContainerSizeMap';
import Breakpoint from '../../../../../../../../design/1/js/lib/types/Breakpoint';
import './index.scss';
const imageAspectRatio = 4 / 3;
const TopReferenceImage = ({ className = '', reference }) => {
    const baseClassName = 'w-referenceGallery-topReferenceImage';
    const breakpoint = useBreakpoint();
    const imageWidth = getImageWidth(breakpoint);
    const imageResizer = new ImageResizer(reference.image.image);
    const backgroundUrl = imageResizer.resize(imageWidth, imageWidth / imageAspectRatio, reference.image.cropModel);
    return (React.createElement("div", { className: `${baseClassName} ${className}`, style: {
            backgroundImage: `url(${backgroundUrl})`,
            backgroundPosition: cropModelToBackgroundPosition(reference.image.cropModel),
        } }));
};
function getImageWidth(breakpoint) {
    if (breakpoint <= Breakpoint.Small) {
        return ContainerSizeMap[breakpoint];
    }
    return ContainerSizeMap[breakpoint] / 2;
}
export default TopReferenceImage;
