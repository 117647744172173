var CropModel;
(function (CropModel) {
    CropModel["LeftTop"] = "lt";
    CropModel["CenterTop"] = "ct";
    CropModel["RightTop"] = "rt";
    CropModel["LeftCenter"] = "lc";
    CropModel["CenterCenter"] = "cc";
    CropModel["RightCenter"] = "rc";
    CropModel["LeftBottom"] = "lb";
    CropModel["CenterBottom"] = "cb";
    CropModel["RightBottom"] = "rb";
})(CropModel || (CropModel = {}));
const regex = /\/_file\/(?<id>\d+)(?:\/(?<model>_[rst]\d+x\d+[lcr][tcb]))?(?:\/(?<name>.*))?/;
class ImageResizer {
    constructor(imageUrl) {
        if (!regex.test(imageUrl)) {
            throw new Error(`URL ${imageUrl} doesn't match regex`);
        }
        this.imageUrl = imageUrl;
    }
    getOriginal() {
        const parts = this.getParts();
        return `/_file/${parts.id}/${parts.name}`;
    }
    resize(width, height, model = CropModel.CenterCenter) {
        const imageModel = (width !== 0 && height !== 0) ? model : '';
        const modelString = `_r${Math.ceil(width)}x${Math.ceil(height)}${imageModel}`;
        const parts = this.getParts();
        return `/_file/${parts.id}/${modelString}/${parts.name}`;
    }
    getParts() {
        var _a, _b;
        const match = this.imageUrl.match(regex);
        return {
            id: match.groups.id,
            model: (_a = match.groups.model) !== null && _a !== void 0 ? _a : '',
            name: (_b = match.groups.name) !== null && _b !== void 0 ? _b : '',
        };
    }
}
function cropModelToBackgroundPosition(cropModel) {
    switch (cropModel) {
        case CropModel.LeftTop: return 'left top';
        case CropModel.CenterTop: return 'center top';
        case CropModel.RightTop: return 'right top';
        case CropModel.LeftCenter: return 'left center';
        case CropModel.CenterCenter:
        default: return 'center center';
        case CropModel.RightCenter: return 'right center';
        case CropModel.LeftBottom: return 'left bottom';
        case CropModel.CenterBottom: return 'center bottom';
        case CropModel.RightBottom: return 'right bottom';
    }
}
function roundDimensions(dimensions, groupSize = 250) {
    return {
        height: Math.ceil(dimensions.height / groupSize) * groupSize,
        width: Math.ceil(dimensions.width / groupSize) * groupSize,
    };
}
export default ImageResizer;
export { CropModel, cropModelToBackgroundPosition, roundDimensions };
