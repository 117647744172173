import React, { useState } from 'react';
import ReferenceFilter from '../referenceFilter';
import FilterOptions from '../../../lib/FilterOptions';
import ReferenceFetcher from '../../../lib/ReferenceFetcher';
import ReferenceView from '../referenceView';
import TextButton, { ButtonColor } from '../../../../../../../../design/1/js/templates/atoms/textButton';
import './index.scss';
const defaultLimit = 12;
const ReferenceGallery = ({ branches, className = '', initialData, loadUrl, products, }) => {
    const baseClassName = 'w-referenceGallery-referenceGallery';
    const [references, setReferences] = useState(initialData);
    const [filterOptions, setFilterOptions] = useState(() => new FilterOptions(null, null));
    const [offset, setOffset] = useState(initialData.length);
    const [hasMore, setHasMore] = useState(true);
    const [showTopReference, setShowTopReference] = useState(true);
    const referenceFetcher = new ReferenceFetcher(loadUrl);
    const fetchMore = (requestFilterOptions, resetReferences = false, limit = defaultLimit) => {
        const requestOffset = resetReferences ? 0 : offset;
        referenceFetcher.fetchReferences(limit, requestOffset, requestFilterOptions)
            .then(response => {
            const newReferences = resetReferences ? [] : references.slice();
            newReferences.push(...response.references);
            setReferences(newReferences);
            setHasMore(response.hasMore);
            setOffset(requestOffset + limit);
            setShowTopReference(requestFilterOptions.branch === null && requestFilterOptions.product === null);
        });
    };
    const filterCallback = (options) => {
        setFilterOptions(options);
        fetchMore(options, true);
    };
    const loadMoreCallback = () => {
        fetchMore(filterOptions);
    };
    return (React.createElement("div", { className: `${baseClassName} ${className}` },
        React.createElement(ReferenceFilter, { className: `${baseClassName}__filter`, branches: branches, filterCallback: filterCallback, products: products }),
        React.createElement(ReferenceView, { className: `${baseClassName}__references`, references: references, showTopReference: showTopReference }),
        hasMore && (React.createElement("div", { className: `${baseClassName}__moreButton` },
            React.createElement(TextButton, { color: ButtonColor.Primary, showArrow: false, handleClick: loadMoreCallback }, "Mehr anzeigen")))));
};
export default ReferenceGallery;
