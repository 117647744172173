import React from 'react';
import './index.scss';
import Close from '../icon/icons/Close';
const FileInput = ({ accept, errorMessage = '', fileName, id, label, multiple = false, onChange, required = false, }) => (React.createElement("div", { className: errorMessage === '' ? 'a-fileInput' : 'a-fileInput -invalid' },
    React.createElement("p", { className: "a-fileInput__label" }, `${label}${required ? ' *' : ''}`),
    React.createElement("div", { className: "a-fileInput__wrapper" },
        React.createElement("label", { className: "a-fileInput__control", htmlFor: id },
            React.createElement("div", { className: "a-fileInput__upload" },
                React.createElement(Close, null)),
            React.createElement("input", { accept: accept, className: "", type: "file", id: id, name: id, onChange: onChange, multiple: multiple })),
        React.createElement("p", { className: "a-fileInput__preview" }, fileName),
        errorMessage !== '' && (React.createElement("span", { className: "a-fileInput__errorMessage" },
            React.createElement("span", { className: "label" }, errorMessage),
            React.createElement("span", { className: "arrow-down" }))))));
export default FileInput;
