export default class BlogArticleFetcher {
    constructor(endpoint) {
        this.endpoint = endpoint;
    }
    fetchArticles(limit, offset, filterOptions) {
        const url = new URL(this.endpoint, window.location.origin);
        url.searchParams.set('limit', limit.toString());
        url.searchParams.set('offset', offset.toString());
        if (filterOptions.theme !== null) {
            url.searchParams.set('theme', filterOptions.theme.id.toString());
        }
        return fetch(url.toString()).then(response => response.json());
    }
}
