import React from 'react';
import Icon, { IconName } from '../../../../../../../../design/1/js/templates/atoms/icon';
import './index.scss';
var ButtonDirection;
(function (ButtonDirection) {
    ButtonDirection[ButtonDirection["Left"] = 0] = "Left";
    ButtonDirection[ButtonDirection["Right"] = 1] = "Right";
})(ButtonDirection || (ButtonDirection = {}));
const CarouselButton = ({ className = '', direction }) => (React.createElement(Icon, { className: `w-referenceCarousel-carouselButton ${className}`, name: direction === ButtonDirection.Left ? IconName.BigArrowLeft : IconName.BigArrowRight, useStroke: true }));
export default CarouselButton;
export { ButtonDirection };
