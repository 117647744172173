import React from 'react';
import Icon, { IconName } from '../icon';
import './index.scss';
var BrandName;
(function (BrandName) {
    BrandName[BrandName["Facebook"] = 0] = "Facebook";
    BrandName[BrandName["Instagram"] = 1] = "Instagram";
    BrandName[BrandName["LinkedIn"] = 2] = "LinkedIn";
})(BrandName || (BrandName = {}));
const brands = {
    [BrandName.Facebook]: {
        color: '#1877f2',
        icon: IconName.Facebook,
    },
    [BrandName.Instagram]: {
        color: '#f00075',
        icon: IconName.Instagram,
    },
    [BrandName.LinkedIn]: {
        color: '#2867b2',
        icon: IconName.LinkedIn,
    },
};
const BrandButton = ({ brand, className = '', handleClick, href, target = '_self', }) => {
    const baseClassName = 'a-brandButton';
    const CustomTag = href !== undefined ? 'a' : 'button';
    return (React.createElement(CustomTag, { className: `${baseClassName} ${className}`, href: href, onClick: handleClick, style: {
            ['--background-color-hover']: brands[brand].color,
        }, target: target },
        React.createElement("div", { className: `${baseClassName}__background` }),
        React.createElement(Icon, { className: `${baseClassName}__icon`, name: brands[brand].icon })));
};
export default BrandButton;
export { BrandName };
