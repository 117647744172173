import Breakpoint from './types/Breakpoint';
const generateBreakpointEvent = (breakpoint) => new CustomEvent('breakpointChange', {
    detail: {
        breakpoint,
    },
});
export const initBreakpointEventListener = () => {
    window.matchMedia(`(min-width: ${Breakpoint.Small}px)`).addEventListener('change', event => {
        const breakpoint = event.matches ? Breakpoint.Small : Breakpoint.XSmall;
        const breakpointEvent = generateBreakpointEvent(breakpoint);
        document.dispatchEvent(breakpointEvent);
    });
    window.matchMedia(`(min-width: ${Breakpoint.Medium}px)`).addEventListener('change', event => {
        const breakpoint = event.matches ? Breakpoint.Medium : Breakpoint.Small;
        const breakpointEvent = generateBreakpointEvent(breakpoint);
        document.dispatchEvent(breakpointEvent);
    });
    window.matchMedia(`(min-width: ${Breakpoint.Large}px)`).addEventListener('change', event => {
        const breakpoint = event.matches ? Breakpoint.Large : Breakpoint.Medium;
        const breakpointEvent = generateBreakpointEvent(breakpoint);
        document.dispatchEvent(breakpointEvent);
    });
    window.matchMedia(`(min-width: ${Breakpoint.XLarge}px)`).addEventListener('change', event => {
        const breakpoint = event.matches ? Breakpoint.XLarge : Breakpoint.Large;
        const breakpointEvent = generateBreakpointEvent(breakpoint);
        document.dispatchEvent(breakpointEvent);
    });
    window.matchMedia(`(min-width: ${Breakpoint.XXLarge}px)`).addEventListener('change', event => {
        const breakpoint = event.matches ? Breakpoint.XXLarge : Breakpoint.XLarge;
        const breakpointEvent = generateBreakpointEvent(breakpoint);
        document.dispatchEvent(breakpointEvent);
    });
};
export const getBreakpoint = () => {
    if (matchesMinWidthMedia(Breakpoint.XXLarge)) {
        return Breakpoint.XXLarge;
    }
    if (matchesMinWidthMedia(Breakpoint.XLarge)) {
        return Breakpoint.XLarge;
    }
    if (matchesMinWidthMedia(Breakpoint.Large)) {
        return Breakpoint.Large;
    }
    if (matchesMinWidthMedia(Breakpoint.Medium)) {
        return Breakpoint.Medium;
    }
    if (matchesMinWidthMedia(Breakpoint.Small)) {
        return Breakpoint.Small;
    }
    return Breakpoint.XSmall;
};
const matchesMinWidthMedia = (width) => {
    const query = window.matchMedia(`(min-width: ${width}px)`);
    return query.matches;
};
