import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import { chunk } from 'lodash';
import LogoSliderPage from '../../molecules/logoPage';
import './index.scss';
import useBreakpoint from '../../../../../../../../design/1/js/lib/hooks/useBreakpoint';
import Breakpoint from '../../../../../../../../design/1/js/lib/types/Breakpoint';
SwiperCore.use([Pagination, Autoplay]);
const LogoSliderApp = ({ logos }) => {
    const baseClassName = 'w-logoSlider-logoSlider';
    const breakpoint = useBreakpoint();
    const pageSize = getPageSize(breakpoint);
    const sliderPages = reorderLogosInPages(logos, pageSize);
    return (React.createElement("div", { className: baseClassName },
        React.createElement(Swiper, { autoplay: {
                delay: 10000,
                disableOnInteraction: false,
            }, loop: true, pagination: { clickable: true }, speed: 2000, tag: "section", wrapperTag: "ul" }, sliderPages.map((logosOnPage, pageNr) => {
            const key = `${baseClassName}-slide-${pageNr}`;
            return (React.createElement(SwiperSlide, { key: key, tag: "li" },
                React.createElement(LogoSliderPage, { logos: logosOnPage })));
        }))));
};
function reorderLogosInPages(logos, pageSize) {
    return chunk(logos, pageSize);
}
function getPageSize(breakpoint) {
    if (breakpoint <= Breakpoint.Small) {
        return 6;
    }
    return 12;
}
export default LogoSliderApp;
