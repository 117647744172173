import './index.scss';
import React, { useState } from 'react';
import AllProjectsButton from '../../atoms/allProjectsButton';
import DesktopView from '../desktopView';
import MobileView from '../mobileView';
import useBreakpoint from '../../../../../../../../design/1/js/lib/hooks/useBreakpoint';
import Breakpoint from '../../../../../../../../design/1/js/lib/types/Breakpoint';
const Carousel = ({ projectOverviewUrl, references }) => {
    const baseClassName = 'w-referenceCarousel-carousel';
    const [index, setIndex] = useState(0);
    const breakpoint = useBreakpoint();
    const onSelectIndex = selectedIndex => {
        if (selectedIndex >= 0 && selectedIndex < references.length) {
            setIndex(selectedIndex);
        }
    };
    const Slider = getSlider(breakpoint);
    return (React.createElement("div", { className: baseClassName },
        React.createElement(Slider, { index: index, onSelectIndex: onSelectIndex, references: references }),
        React.createElement("div", { className: `${baseClassName}__buttonAllWrapper` },
            React.createElement(AllProjectsButton, { className: `${baseClassName}__buttonAll`, text: "Alle Projekte", url: projectOverviewUrl }))));
};
function getSlider(breakpoint) {
    if (breakpoint <= Breakpoint.Small) {
        return MobileView;
    }
    return DesktopView;
}
export default Carousel;
