export default class ReferenceFetcher {
    constructor(endpoint) {
        this.endpoint = endpoint;
    }
    fetchReferences(limit, offset, filterOptions) {
        const url = new URL(this.endpoint, window.location.origin);
        url.searchParams.set('limit', limit.toString());
        url.searchParams.set('offset', offset.toString());
        if (filterOptions.branch !== null) {
            url.searchParams.set('branch', filterOptions.branch.id.toString());
        }
        if (filterOptions.product !== null) {
            url.searchParams.set('product', filterOptions.product.id.toString());
        }
        return fetch(url.toString()).then(response => response.json());
    }
}
