import React from 'react';
import ImageResizer, { cropModelToBackgroundPosition } from '../../../../../../../../design/1/js/lib/ImageResizer';
import useBreakpoint from '../../../../../../../../design/1/js/lib/hooks/useBreakpoint';
import ContainerSizeMap from '../../../../../../../../design/1/js/lib/types/ContainerSizeMap';
import Breakpoint from '../../../../../../../../design/1/js/lib/types/Breakpoint';
import './index.scss';
const imageAspectRatio = 4 / 3;
const GalleryTile = ({ className = '', member }) => {
    const baseClassName = 'w-team-galleryTile';
    const breakpoint = useBreakpoint();
    const containerWidth = ContainerSizeMap[breakpoint];
    const imageResizer = new ImageResizer(member.image);
    const imageWidth = containerWidth * getImageWidthMultiplier(breakpoint);
    const resizedImage = imageResizer.resize(imageWidth, imageWidth / imageAspectRatio);
    return (React.createElement("div", { className: `${baseClassName} ${className}` },
        React.createElement("div", { className: `${baseClassName}__image`, style: {
                backgroundImage: `url(${resizedImage})`,
                backgroundPosition: cropModelToBackgroundPosition(member.imageCropModel),
            } }),
        React.createElement("div", { className: `${baseClassName}__content background-dark` },
            React.createElement("div", { className: `${baseClassName}__name header3` }, member.name),
            React.createElement("div", { className: `${baseClassName}__description` }, member.description.join(', ')))));
};
function getImageWidthMultiplier(breakpoint) {
    if (breakpoint < Breakpoint.Medium) {
        return 1;
    }
    if (breakpoint < Breakpoint.Large) {
        return 1 / 2;
    }
    return 1 / 3;
}
export default GalleryTile;
