import React from 'react';
import OverflowText from '../../../../../../../../design/1/js/templates/atoms/overflowText';
const ItemContent = ({ content, className = '' }) => {
    const baseClassName = 'w-search-itemContent';
    return (React.createElement(OverflowText, { className: `${baseClassName} ${className}`, lines: 1 },
        React.createElement("div", { 
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML: { __html: content } })));
};
export default ItemContent;
