import React from 'react';
import ReactDOM from 'react-dom';
import ReferenceGallery from './templates/organisms/referenceGallery';
document.addEventListener('DOMContentLoaded', () => {
    const referenceGalleries = document.querySelectorAll('.plf-reference-gallery');
    for (const referenceGallery of referenceGalleries) {
        const branches = JSON.parse(referenceGallery.dataset.branches);
        const initialData = JSON.parse(referenceGallery.dataset.initialData);
        const loadUrl = referenceGallery.dataset.loadUrl;
        const products = JSON.parse(referenceGallery.dataset.products);
        ReactDOM.render(React.createElement(ReferenceGallery, { branches: branches, initialData: initialData, loadUrl: loadUrl, products: products }), referenceGallery);
    }
});
