import React from 'react';
import ImageResizer from '../../../../../../../../design/1/js/lib/ImageResizer';
import useBreakpoint from '../../../../../../../../design/1/js/lib/hooks/useBreakpoint';
import ContainerSizeMap from '../../../../../../../../design/1/js/lib/types/ContainerSizeMap';
const imageAspectRatio = 16 / 9;
const Image = ({ alt, className = '', image, imageCropModel, }) => {
    const breakpoint = useBreakpoint();
    const imageResizer = new ImageResizer(image);
    const imageWidth = ContainerSizeMap[breakpoint];
    const imageUrl = imageResizer.resize(imageWidth, imageWidth / imageAspectRatio, imageCropModel);
    return (React.createElement("img", { alt: alt, className: className, src: imageUrl }));
};
export default Image;
