import React from 'react';
import './index.scss';
import { ButtonColor } from '../textButton';
const SplitActionButton = ({ action, children, className = '', color = ButtonColor.Primary, onClick, onClickAction, }) => {
    const baseClassName = 'a-splitActionButton';
    return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    React.createElement("div", { className: `${baseClassName} ${className} -${color}`, onClick: onClick },
        React.createElement("div", { className: `${baseClassName}__content` }, children),
        action && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        React.createElement("div", { className: `${baseClassName}__button`, onClick: onClickAction && (event => {
                event.stopPropagation();
                onClickAction();
            }) }, action))));
};
export default SplitActionButton;
