function nextIndex(current, length) {
    if (current + 1 >= length) {
        return 0;
    }
    return current + 1;
}
function previousIndex(current, length) {
    if (current - 1 < 0) {
        return length - 1;
    }
    return current - 1;
}
function getIndex(current, increase, length) {
    let cur = current;
    let inc = increase;
    if (increase > 0) {
        do {
            cur = nextIndex(cur, length);
            inc--;
        } while (inc > 0);
    }
    if (increase < 0) {
        do {
            cur = previousIndex(cur, length);
            inc++;
        } while (inc < 0);
    }
    return cur;
}
function getClosestIncrease(from, to, length) {
    const halfLength = length / 2;
    if (Math.abs(from - to) <= halfLength) {
        return to - from;
    }
    if (from > to) {
        return (length + to) - from;
    }
    return to - (length + from);
}
export { nextIndex, previousIndex, getIndex, getClosestIncrease, };
