export default class SearchItemFetcher {
    constructor(endpoint) {
        this.endpoint = endpoint;
    }
    fetchSearchItems(searchstring, page) {
        const url = new URL(this.endpoint, window.location.origin);
        url.searchParams.set('searchstring', searchstring);
        url.searchParams.set('page', page.toString());
        return fetch(url.toString()).then(response => response.json());
    }
}
