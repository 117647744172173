import ReactDOM from 'react-dom';
import React from 'react';
import Parallax from './templates/organisms/parallax';
document.addEventListener('DOMContentLoaded', () => {
    const parallaxes = document.querySelectorAll('.plf-parallax');
    parallaxes.forEach(parallax => {
        const parallaxStories = JSON.parse(parallax.dataset.parallaxStories);
        const parallaxHero = JSON.parse(parallax.dataset.parallaxHero);
        ReactDOM.render(React.createElement(Parallax, { parallaxHero: parallaxHero, parallaxStories: parallaxStories }), parallax);
    });
});
