import React from 'react';
import Image from '../../atoms/image';
import './index.scss';
import CustomerName from '../../atoms/customerName';
const ImageWrapper = ({ children, className = '', classNameImage = '', classNameCustomer = '', reference, }) => {
    const baseClassName = 'w-referenceCarousel-imageWrapper';
    return (React.createElement("a", { className: `${baseClassName} ${className}`, href: reference.link },
        React.createElement(CustomerName, { customerName: reference.customer, className: `${baseClassName}__customerName background-dark ${classNameCustomer}` }),
        React.createElement(Image, { alt: reference.title, className: `${baseClassName}__image -blurred ${classNameImage}`, image: reference.image, imageCropModel: reference.imageCropModel }),
        React.createElement(Image, { alt: reference.title, className: `${baseClassName}__image -crisp ${classNameImage}`, image: reference.image, imageCropModel: reference.imageCropModel }),
        children));
};
export default ImageWrapper;
