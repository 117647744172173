import React from 'react';
import ImageResizer from '../../../lib/ImageResizer';
import ContainerSizeMap from '../../../lib/types/ContainerSizeMap';
import useBreakpoint from '../../../lib/hooks/useBreakpoint';
import './index.scss';
import { IconName } from '../icon';
import CircularButton from '../circularButton';
import { ButtonColor } from '../textButton';
import Breakpoint from '../../../lib/types/Breakpoint';
const imageAspectRatio = 4 / 3;
const GalleryImage = ({ className, darken = false, image, onClick, }) => {
    const baseClassName = 'm-galleryImage';
    const breakpoint = useBreakpoint();
    const imageResizer = new ImageResizer(image.image);
    const containerWidth = ContainerSizeMap[breakpoint];
    const imageWidth = containerWidth * getImageWidthMultiplier(breakpoint);
    const imageLink = imageResizer.resize(imageWidth, imageWidth / imageAspectRatio);
    return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    React.createElement("div", { className: `${baseClassName} ${className} ${darken ? '-darken' : ''}`, onClick: onClick },
        React.createElement("img", { className: `${baseClassName}__image`, src: imageLink, alt: image.alt }),
        React.createElement("div", { className: `${baseClassName}__buttonOverlay` },
            React.createElement(CircularButton, { className: `${baseClassName}__button`, color: ButtonColor.Dark, icon: IconName.Search, inverted: true, useStroke: true }))));
};
function getImageWidthMultiplier(breakpoint) {
    if (breakpoint < Breakpoint.Medium) {
        return 1;
    }
    if (breakpoint < Breakpoint.Large) {
        return 1 / 2;
    }
    return 1 / 3;
}
GalleryImage.defaultProps = {
    className: '',
    onClick: () => { },
};
export default GalleryImage;
