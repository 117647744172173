import { useState } from 'react';
import { getBreakpoint } from '../breakpoints';
import useEventListener from './useEventListener';
const useBreakpoint = () => {
    const [breakpoint, setBreakpoint] = useState(() => getBreakpoint());
    useEventListener('breakpointChange', (event) => {
        setBreakpoint(event.detail.breakpoint);
    }, document);
    return breakpoint;
};
export default useBreakpoint;
