import React from 'react';
import './index.scss';
const SearchTitle = ({ className = '', totalcount, searchstring }) => {
    const baseClassName = 'w-search-searchTitle';
    const isOnlyOne = parseInt(totalcount, 10) === 1 ? 'Eintrag' : 'Einträge';
    return (React.createElement("div", { className: `${baseClassName} ${className} header1` },
        "Suche nach",
        ' ',
        React.createElement("span", { className: `${baseClassName}__searchstring` },
            "\u00AB",
            searchstring,
            "\u00BB"),
        ":",
        ' ',
        totalcount,
        ' ',
        isOnlyOne,
        ' ',
        "gefunden"));
};
export default SearchTitle;
