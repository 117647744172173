import React from 'react';
import ReactDOM from 'react-dom';
import Contact from './templates/organisms/contact';
import PersonOfTheDay from './templates/organisms/personOfTheDay';
import Gallery from './templates/organisms/gallery';
document.addEventListener('DOMContentLoaded', () => {
    const teamWidgets = document.querySelectorAll('.plf-team');
    teamWidgets.forEach(teamWidget => {
        const type = teamWidget.dataset.type;
        switch (type) {
            case 'contact_person': {
                const contact = JSON.parse(teamWidget.dataset.contact);
                if (contact !== null) {
                    ReactDOM.render(React.createElement(Contact, { contact: contact }), teamWidget);
                }
                break;
            }
            case 'person_of_the_day': {
                const teamMember = JSON.parse(teamWidget.dataset.teamMember);
                const teamOverviewPage = teamWidget.dataset.teamOverviewPage;
                ReactDOM.render(React.createElement(PersonOfTheDay, { teamMember: teamMember, teamOverviewPage: teamOverviewPage }), teamWidget);
                break;
            }
            case 'gallery': {
                const teamMembers = JSON.parse(teamWidget.dataset.members);
                ReactDOM.render(React.createElement(Gallery, { members: teamMembers }), teamWidget);
                break;
            }
            default:
                break;
        }
    });
});
