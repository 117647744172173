import { useEffect, useLayoutEffect, useState, } from 'react';
import useScreenSize from './useScreenSize';
import { roundDimensions } from '../ImageResizer';
export default function useElementDimensions(elementRef, roundFunction = roundDimensions) {
    const [elementDimensions, setElementDimensions] = useState(null);
    const screenSize = useScreenSize();
    const calculateDimensions = () => {
        if (elementRef.current !== null) {
            setElementDimensions(roundFunction({
                height: elementRef.current.offsetHeight,
                width: elementRef.current.offsetWidth,
            }));
        }
    };
    useEffect(() => {
        calculateDimensions();
    }, [screenSize]);
    useLayoutEffect(() => {
        calculateDimensions();
    }, []);
    return elementDimensions;
}
