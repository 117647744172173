import React from 'react';
import Icon, { IconName } from '../icon';
import './index.scss';
import { ButtonColor } from '../textButton';
const CircularButton = ({ className = '', color = ButtonColor.Primary, customDiameter = false, icon = IconName.ArrowRight, inverted = false, handleClick, target = '_self', url, useStroke = false, }) => {
    const baseClassName = 'a-circularButton';
    const CustomTag = url ? 'a' : 'button';
    return (React.createElement(CustomTag, { className: `${baseClassName} -${color} ${inverted ? '-inverted' : ''} ${customDiameter ? '-customDiameter' : ''} ${useStroke ? '-overriddenStroke' : ''} ${className}`, href: url, onClick: handleClick, target: target },
        React.createElement("div", { className: `${baseClassName}__background` }),
        React.createElement(Icon, { className: `${baseClassName}__icon`, name: icon, useStroke: true })));
};
export default CircularButton;
