import React from 'react';
import './index.scss';
const RadioInput = ({ checked, className = '', handleChange, id, label, name, }) => {
    const baseClassName = 'a-radioInput';
    return (React.createElement("label", { className: `${baseClassName} ${className}`, htmlFor: id },
        React.createElement("span", { className: `${baseClassName}__input` },
            React.createElement("input", { checked: checked, id: id, name: name, onChange: handleChange, type: "radio", value: id }),
            React.createElement("span", { className: `${baseClassName}__control` })),
        React.createElement("span", { className: `${baseClassName}__label` }, label)));
};
export default RadioInput;
