import React from 'react';
import TextButton, { TextButtonSize } from '../../../../../../../../design/1/js/templates/atoms/textButton';
import ProjectFacts from '../../atoms/projectFacts';
import './index.scss';
const ProjectDetails = ({ className = '', reference }) => {
    const baseClassName = 'w-referencedetail-projectDetails';
    return (React.createElement("div", { className: `${baseClassName} ${className}` },
        React.createElement("div", { className: `${baseClassName}__rubric rubric1` }, "Projekt-Details"),
        React.createElement("div", { className: `${baseClassName}__content` },
            React.createElement("div", { className: `${baseClassName}__keyPoints` },
                React.createElement("div", { className: `${baseClassName}__keyPointsTitle header3` }, "Projekt-Schwerpunkte"),
                React.createElement("div", { className: `${baseClassName}__keyPointsContent`, 
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML: { __html: reference.keyPoints } })),
            React.createElement("div", { className: `${baseClassName}__facts` },
                React.createElement("div", { className: `${baseClassName}__factsTitle header3` }, "Facts & Figures"),
                React.createElement(ProjectFacts, { className: `${baseClassName}__factsContent`, reference: reference }))),
        reference.link && (React.createElement("div", { className: `${baseClassName}__button` },
            React.createElement(TextButton, { url: reference.link, size: TextButtonSize.Normal, target: "_blank" }, `Website von ${reference.client.name} besuchen`)))));
};
export default ProjectDetails;
