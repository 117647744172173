import React, { useRef, useState } from 'react';
import useIntersectionObserver from '../../../../../../../../design/1/js/lib/hooks/useIntersectionObserver';
import useScreenSize from '../../../../../../../../design/1/js/lib/hooks/useScreenSize';
import './index.scss';
const AnimationWrapper = ({ children, className = '', }) => {
    const baseClassName = 'w-parallax-animationWrapper';
    const contentRef = useRef(null);
    const screenDimensions = useScreenSize();
    const [isVisible, setVisible] = useState(false);
    useIntersectionObserver(contentRef, {
        // Bottom margin to fix threshold not being triggered when scrolling quickly
        // Left / right margin because elements are initially translated to the right
        rootMargin: `-60% 100% ${screenDimensions.height * 1000}px`,
        threshold: 1,
    }, entries => {
        entries.forEach(entry => {
            if (entry.boundingClientRect.top < entry.rootBounds.top) {
                setVisible(true);
            }
        });
    });
    return (React.createElement("div", { ref: contentRef, className: `${baseClassName} ${isVisible ? '-visible' : ''}` },
        React.createElement("div", { className: `${baseClassName}__content ${className}` }, children)));
};
export default AnimationWrapper;
