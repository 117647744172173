import React from 'react';
import TopReference from '../../molecules/topReference';
import ReferenceGrid from '../../molecules/referenceGrid';
import './index.scss';
const ReferenceView = ({ className = '', references, showTopReference, }) => {
    const baseClassName = 'w-referenceGallery-referenceView';
    if (references.length === 0) {
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement("div", { className: `${baseClassName} ${className} full-width-mobile-content` }, showTopReference ? (React.createElement(React.Fragment, null,
        React.createElement(TopReference, { className: `${baseClassName}__topReference`, reference: references[0] }),
        React.createElement(ReferenceGrid, { className: `${baseClassName}__referenceGrid`, references: references.slice(1) }))) : (React.createElement(ReferenceGrid, { className: `${baseClassName}__referenceGrid`, references: references }))));
};
export default ReferenceView;
