import React from 'react';
import ReactDOM from 'react-dom';
import Reference from './templates/organisms/reference';
document.addEventListener('DOMContentLoaded', () => {
    const detailWidgets = document.querySelectorAll('.plf-reference-detail');
    detailWidgets.forEach(detailWidget => {
        const reference = JSON.parse(detailWidget.dataset.reference);
        ReactDOM.render(React.createElement(Reference, { reference: reference }), detailWidget);
    });
});
