import React from 'react';
import './index.scss';
import ReferenceTile from '../referenceTile';
import Breakpoint from '../../../../../../../../design/1/js/lib/types/Breakpoint';
import ContainerSizeMap from '../../../../../../../../design/1/js/lib/types/ContainerSizeMap';
import useBreakpoint from '../../../../../../../../design/1/js/lib/hooks/useBreakpoint';
const ReferenceGrid = ({ className = '', references }) => {
    const baseClassName = 'w-referenceGallery-referenceGrid';
    const breakpoint = useBreakpoint();
    return (React.createElement("div", { className: `${baseClassName} ${className}` }, references.map(reference => (React.createElement(ReferenceTile, { key: reference.id, reference: reference, tileWidth: getTileWidth(breakpoint) })))));
};
function getTileWidth(breakpoint) {
    if (breakpoint >= Breakpoint.XXLarge) {
        return ContainerSizeMap[breakpoint] / 3;
    }
    if (breakpoint >= Breakpoint.Medium) {
        return ContainerSizeMap[breakpoint] / 2;
    }
    return ContainerSizeMap[breakpoint];
}
export default ReferenceGrid;
