import React from 'react';
import ImageHeader from '../../../../../../../../design/1/js/templates/molecules/imageHeader';
import './index.scss';
const Header = ({ className = '', reference }) => {
    const baseClassName = 'w-referenceDetail-header';
    return (React.createElement("div", { className: `${baseClassName} ${className}` },
        React.createElement(ImageHeader, { className: `${baseClassName} ${className}`, cropModel: reference.header.cropModel, hasScrollButton: true, image: reference.header.image },
            React.createElement("div", { className: `${baseClassName}__client rubric2` }, reference.client.name),
            React.createElement("h1", { className: `${baseClassName}__title display` }, reference.title))));
};
export default Header;
