import React from 'react';
import Icon, { IconName } from '../../atoms/icon';
import './index.scss';
import useBodyScroll from '../../../lib/hooks/useBodyScroll';
import TextButton, { ButtonColor, TextButtonSize } from '../../atoms/textButton';
import SplitActionButton from '../../atoms/splitActionButton';
const FilterMobile = ({ children, className = '', formik, isOpen, setOpen, }) => {
    const baseClassName = 'o-filterMobile';
    useBodyScroll(!isOpen);
    return (React.createElement("div", { className: `${baseClassName} ${className}` },
        React.createElement(SplitActionButton, { action: formik.dirty && formik.isValid && formik.submitCount > 0 && React.createElement(Icon, { className: `${baseClassName}__closeIcon`, name: IconName.Close, useStroke: true }), color: ButtonColor.GrayLight, onClick: () => setOpen(true), onClickAction: () => formik.resetForm() },
            React.createElement("div", { className: `${baseClassName}__header` },
                React.createElement(Icon, { className: `${baseClassName}__icon`, name: IconName.Filter, useStroke: true }),
                React.createElement("div", { className: `${baseClassName}__text` }, "Filtern"))),
        isOpen && (React.createElement("div", { className: `${baseClassName}__overlay` },
            React.createElement("div", { className: `${baseClassName}__overlayHeader` },
                React.createElement("div", { className: `${baseClassName}__overlayTitle header2` }, "Filter"),
                React.createElement("div", { className: `${baseClassName}__overlayClose`, onClick: () => setOpen(false) },
                    React.createElement(Icon, { name: IconName.Close, useStroke: true }))),
            React.createElement("div", { className: `${baseClassName}__overlayContent` },
                children,
                formik.dirty && formik.isValid ? (React.createElement(TextButton, { className: `${baseClassName}__overlayButton`, color: ButtonColor.Primary, size: TextButtonSize.Normal, handleClick: () => {
                        setOpen(false);
                        formik.submitForm();
                    } }, "Resultate")) : (React.createElement(TextButton, { className: `${baseClassName}__overlayButton`, color: ButtonColor.GrayDark, size: TextButtonSize.Normal, handleClick: () => setOpen(false) }, "Schliessen")))))));
};
export default FilterMobile;
