import React from 'react';
import BrandButton, { BrandName } from '../../atoms/brandButton';
import DecryptMailAnchor from '../../atoms/decryptMailAnchor';
import './index.scss';
const Links = {
    // eslint-disable-next-line no-script-url
    Email: 'pdlowr=lqirClqwhuvlp1fk',
    EmailView: 'E-Mail an Intersim',
    Phone: 'tel:+41345333050',
    PhoneView: 'T +41 34 533 30 50',
    Maps: 'https://goo.gl/maps/dub5gM2BTteNQbUUA',
    Facebook: 'https://www.facebook.com/intersim/',
    Instagram: 'https://www.instagram.com/intersimag/',
    LinkedIn: 'https://www.linkedin.com/company/intersim-ag-z-rich/',
};
const FooterContact = ({ className = '', menu }) => {
    const baseClassName = 'm-footerContact';
    return (React.createElement("div", { className: `${baseClassName} ${className}` },
        React.createElement("address", { className: `${baseClassName}__address` },
            React.createElement("p", null,
                React.createElement("strong", null, "Intersim AG"),
                React.createElement("br", null),
                "Kirchbergstrasse 105",
                React.createElement("br", null),
                "CH-3400 Burgdorf"),
            React.createElement("p", null,
                React.createElement("a", { href: Links.Phone }, Links.PhoneView),
                React.createElement("br", null),
                React.createElement(DecryptMailAnchor, { email: Links.Email }, Links.EmailView),
                React.createElement("br", null),
                React.createElement("a", { href: Links.Maps, target: "_blank" }, "Zum Standort auf Google Maps"))),
        React.createElement("div", { className: `${baseClassName}__media` },
            React.createElement(BrandButton, { className: `${baseClassName}__mediaButton`, brand: BrandName.LinkedIn, href: Links.LinkedIn, target: "_blank" }),
            React.createElement(BrandButton, { className: `${baseClassName}__mediaButton`, brand: BrandName.Instagram, href: Links.Instagram, target: "_blank" }),
            React.createElement(BrandButton, { className: `${baseClassName}__mediaButton`, brand: BrandName.Facebook, href: Links.Facebook, target: "_blank" })),
        React.createElement("div", { className: `${baseClassName}__link` },
            React.createElement("a", { href: menu.links.imprint }, "Impressum")),
        React.createElement("div", { className: `${baseClassName}__link` },
            React.createElement("a", { href: menu.links.privacy }, "Datenschutz")),
        React.createElement("div", { className: `${baseClassName}__link` },
            React.createElement("button", { type: "button", className: "button-link", onClick: () => window.UC_UI.showSecondLayer() }, "Cookies"))));
};
export default FooterContact;
