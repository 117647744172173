import React from 'react';
import CircularButton from '../circularButton';
import { ButtonColor } from '../textButton';
import './index.scss';
const ScrollButton = ({ animate = false, className = '', inverted = true, onClick, }) => {
    const baseClassName = 'a-scrollButton';
    return (React.createElement("div", { className: `${baseClassName} ${className}` },
        React.createElement(CircularButton, { className: `${baseClassName}__button ${animate ? '-animate' : ''}`, color: ButtonColor.Dark, inverted: inverted, handleClick: onClick })));
};
export default ScrollButton;
