import React from 'react';
import ReactDOM from 'react-dom';
import Teaser from './templates/organisms/teaser';
import Gallery from './templates/organisms/gallery';
document.addEventListener('DOMContentLoaded', () => {
    const blogs = document.querySelectorAll('.plf-blog');
    for (const blog of blogs) {
        const type = blog.dataset.type;
        if (type === 'teaser') {
            const allArticlesLink = blog.dataset.allArticlesLink;
            const articles = JSON.parse(blog.dataset.articles);
            ReactDOM.render(React.createElement(Teaser, { allArticlesLink: allArticlesLink, articles: articles }), blog);
        }
        else if (type === 'gallery') {
            const initialData = JSON.parse(blog.dataset.initialData);
            const loadUrl = blog.dataset.loadUrl;
            const themes = JSON.parse(blog.dataset.themes);
            ReactDOM.render(React.createElement(Gallery, { initialData: initialData, loadUrl: loadUrl, themes: themes }), blog);
        }
    }
});
