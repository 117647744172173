import React from 'react';
import ArrowRight from './icons/ArrowRight';
import BigArrowLeft from './icons/BigArrowLeft';
import BigArrowRight from './icons/BigArrowRight';
import BrandFacebook from './icons/BrandFacebook';
import BrandInstagram from './icons/BrandInstagram';
import BrandLinkedIn from './icons/BrandLinkedIn';
import BrandTwitter from './icons/BrandTwitter';
import BrandXing from './icons/BrandXing';
import Burger from './icons/Burger';
import Close from './icons/Close';
import Contact from './icons/Contact';
import Filter from './icons/Filter';
import IntersimLogo from './icons/IntersimLogo';
import Like from './icons/Like';
import Mail from './icons/Mail';
import Search from './icons/Search';
import Share from './icons/Share';
import './index.scss';
export var IconName;
(function (IconName) {
    IconName[IconName["ArrowRight"] = 0] = "ArrowRight";
    IconName[IconName["BigArrowRight"] = 1] = "BigArrowRight";
    IconName[IconName["BigArrowLeft"] = 2] = "BigArrowLeft";
    IconName[IconName["IntersimLogo"] = 3] = "IntersimLogo";
    IconName[IconName["Facebook"] = 4] = "Facebook";
    IconName[IconName["Instagram"] = 5] = "Instagram";
    IconName[IconName["LinkedIn"] = 6] = "LinkedIn";
    IconName[IconName["Twitter"] = 7] = "Twitter";
    IconName[IconName["Xing"] = 8] = "Xing";
    IconName[IconName["Burger"] = 9] = "Burger";
    IconName[IconName["Close"] = 10] = "Close";
    IconName[IconName["Contact"] = 11] = "Contact";
    IconName[IconName["Filter"] = 12] = "Filter";
    IconName[IconName["Like"] = 13] = "Like";
    IconName[IconName["Search"] = 14] = "Search";
    IconName[IconName["Share"] = 15] = "Share";
    IconName[IconName["Mail"] = 16] = "Mail";
})(IconName || (IconName = {}));
const icons = {
    [IconName.ArrowRight]: ArrowRight,
    [IconName.BigArrowRight]: BigArrowRight,
    [IconName.BigArrowLeft]: BigArrowLeft,
    [IconName.IntersimLogo]: IntersimLogo,
    [IconName.Facebook]: BrandFacebook,
    [IconName.Instagram]: BrandInstagram,
    [IconName.LinkedIn]: BrandLinkedIn,
    [IconName.Twitter]: BrandTwitter,
    [IconName.Xing]: BrandXing,
    [IconName.Burger]: Burger,
    [IconName.Close]: Close,
    [IconName.Contact]: Contact,
    [IconName.Filter]: Filter,
    [IconName.Like]: Like,
    [IconName.Search]: Search,
    [IconName.Share]: Share,
    [IconName.Mail]: Mail,
};
const Icon = ({ className = '', name, useFill = false, useStroke = false, }) => {
    const Ico = icons[name];
    return (React.createElement("div", { className: `a-icon ${useFill ? '-useFill' : ''} ${useStroke ? '-useStroke' : ''} ${className}` },
        React.createElement(Ico, null)));
};
export default Icon;
