import React from 'react';
import './index.scss';
const TextInput = ({ errorMessage = '', onChange, onBlur, id, label, required = false, type = 'text', value, }) => (React.createElement("label", { className: errorMessage === '' ? 'a-textInput' : 'a-textInput -invalid', htmlFor: id },
    React.createElement("p", { className: "a-textInput__label" },
        label,
        required && ('*')),
    React.createElement("div", { className: "a-textInput__wrapper" },
        React.createElement("input", { className: "a-textInput__control", type: type, id: id, name: id, onChange: onChange, onBlur: onBlur, value: value }),
        errorMessage !== '' && (React.createElement("span", { className: "a-textInput__errorMessage" },
            React.createElement("span", { className: "label" }, errorMessage),
            React.createElement("span", { className: "arrow-down" }))))));
export default TextInput;
