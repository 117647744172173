var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useEffect, useRef, useState, } from 'react';
import { useFormik } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import TextButton, { ButtonColor } from '../../../../../../../../design/1/js/templates/atoms/textButton';
import AnswerText from '../../atoms/answerText';
import './index.scss';
import LoadSpinner from '../../../../../../../../design/1/js/templates/atoms/loadSpinner';
const Form = ({ actionUrl, children, className = '', fileFields = [], handleValidate, initialValues, name, recaptchaKey, teaserContent, teaserTitle, useLoadSpinner = false, }) => {
    const baseClassName = 'w-forms-form';
    const useRecaptcha = recaptchaKey !== undefined;
    const formRef = useRef(null);
    const recaptchaRef = useRef(null);
    const [responseStatus, setResponseStatus] = useState(0);
    const [responseMessage, setResponseMessage] = useState('');
    const [showLoadSpinner, setShowLoadSpinner] = useState(false);
    useEffect(() => {
        if (responseStatus === 200) {
            formRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            });
        }
    }, [responseStatus]);
    const fetchContent = useCallback((formData) => __awaiter(void 0, void 0, void 0, function* () {
        const sendFormData = new FormData();
        for (const entry of Object.entries(formData)) {
            const [key, value] = entry;
            if (fileFields.includes(key)) {
                const isMultiple = Array.isArray(value);
                const files = (isMultiple ? value : [value]);
                for (const file of files) {
                    const originalFile = file.originalFile;
                    if (originalFile !== undefined) {
                        sendFormData.append(`${key}${isMultiple ? '[]' : ''}`, originalFile);
                    }
                }
            }
            else {
                sendFormData.append(key, value);
            }
        }
        return fetch(actionUrl, {
            method: 'POST',
            body: sendFormData,
            headers: {
                'X-Csrf-Token': document.body.dataset.csrfToken,
            },
        });
    }), [actionUrl, fileFields]);
    const handleSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        let formData = Object.assign({}, values);
        if (useRecaptcha) {
            if (recaptchaRef.current === null) {
                setResponseStatus(400);
                setResponseMessage('Recaptcha is undefined');
                return;
            }
            const token = yield recaptchaRef.current.executeAsync();
            formData = Object.assign({ recaptchaToken: token }, formData);
        }
        if (useLoadSpinner) {
            setShowLoadSpinner(true);
        }
        const response = yield fetchContent(formData);
        if (useLoadSpinner) {
            setShowLoadSpinner(false);
        }
        const jsonResponse = yield response.json();
        setResponseStatus(jsonResponse.status);
        setResponseMessage(jsonResponse.message);
    });
    const formik = useFormik({
        initialValues,
        onSubmit: handleSubmit,
        validate: handleValidate,
    });
    useEffect(() => {
        if (formik.submitCount > 0 && !formik.isSubmitting) {
            const firstErrorMessage = document.querySelectorAll('span[class$="__errorMessage"]')[0];
            if (firstErrorMessage !== undefined) {
                firstErrorMessage.scrollIntoView();
            }
        }
    }, [formik.isSubmitting]);
    return (React.createElement("div", { ref: formRef, className: `${baseClassName} ${className} background-secondary full-width-mobile-content` },
        React.createElement(LoadSpinner, { show: showLoadSpinner, message: "Die Verarbeitung des Formulares kann einige Sekunden dauern." }),
        responseStatus === 0 && !showLoadSpinner && (React.createElement("form", { name: name, onSubmit: formik.handleSubmit },
            React.createElement("p", { className: "rubric2" }, name),
            (teaserTitle.trim() !== '' || teaserContent !== '') && (React.createElement("div", { className: `${baseClassName}__teaser` },
                React.createElement("h2", { className: `${baseClassName}__teaserTitle` }, teaserTitle),
                React.createElement("p", { className: `${baseClassName}__teaserContent lead` }, teaserContent))),
            useRecaptcha && (React.createElement(ReCAPTCHA, { ref: recaptchaRef, sitekey: recaptchaKey, size: "invisible" })),
            children !== undefined && children(formik),
            React.createElement("div", { className: `${baseClassName}__buttonSendWrapper` },
                React.createElement(TextButton, { className: `${baseClassName}__textButtonStretched`, color: ButtonColor.Secondary, inverted: true, type: "submit" }, "Senden")))),
        responseStatus !== 0 && !showLoadSpinner && (React.createElement(AnswerText, { status: responseStatus, message: responseMessage }))));
};
export default Form;
