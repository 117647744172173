import { useRef, useState } from 'react';
import useEventListener from './useEventListener';
const useScreenSize = (debounceTimeout = 100) => {
    const [dimensions, setDimensions] = useState(() => ({
        height: window.innerHeight,
        width: window.innerWidth,
    }));
    const timeoutId = useRef(null);
    useEventListener('resize', () => {
        if (timeoutId.current !== null) {
            window.clearTimeout(timeoutId.current);
        }
        timeoutId.current = window.setTimeout(() => {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth,
            });
        }, debounceTimeout);
    }, window);
    return dimensions;
};
export default useScreenSize;
