import React from 'react';
import './index.scss';
const Testimonial = ({ className = '', reference }) => {
    const baseClassName = 'w-referencedetail-testimonial';
    if (reference.testimonial === null) {
        return React.createElement("div", { className: `${baseClassName} -empty ${className}` });
    }
    return (React.createElement("div", { className: `${baseClassName} ${className}` },
        React.createElement("blockquote", { className: `${baseClassName}__content lead` }, reference.testimonial.content),
        React.createElement("div", { className: `${baseClassName}__info` },
            React.createElement("span", { className: `${baseClassName}__person` }, reference.testimonial.person),
            ', ',
            React.createElement("span", { className: `${baseClassName}__position` }, reference.testimonial.position),
            ' ',
            React.createElement("span", { className: `${baseClassName}__client` }, reference.testimonial.client))));
};
export default Testimonial;
