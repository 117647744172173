import React from 'react';
import OverflowText from '../../../../../../../../design/1/js/templates/atoms/overflowText';
import './index.scss';
const Lead = ({ className = '', lead }) => {
    const baseClassName = 'w-referenceCarousel-lead';
    return (React.createElement(OverflowText, { className: `${baseClassName} ${className}`, lines: 4 },
        React.createElement("div", { 
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML: { __html: lead } })));
};
export default Lead;
