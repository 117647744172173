import React from 'react';
import Header from '../../molecules/header';
import BlogContent from '../../molecules/blogContent';
import Gallery from '../../../../../../../../design/1/js/templates/molecules/gallery';
import SocialBar from '../../molecules/socialBar';
import useBooleanStateWithLocalStorage from '../../../../../../../../design/1/js/lib/hooks/useBooleanStateWithLocalStorage';
import './index.scss';
const BlogDetail = ({ blogArticle, className, id, likeUrl, }) => {
    const baseClassName = 'w-blogDetail';
    const [hasUserLiked, setHasUserLiked] = useBooleanStateWithLocalStorage(`hasUserLiked-${id}`);
    return (React.createElement("div", { className: `${baseClassName} ${className}` },
        React.createElement(Header, { blogArticle: blogArticle, className: `${baseClassName}__header` }),
        React.createElement(BlogContent, { blogArticle: blogArticle, className: `${baseClassName}__content` }),
        React.createElement(Gallery, { images: blogArticle.images, className: `${baseClassName}__gallery` }),
        React.createElement(SocialBar, { likes: blogArticle.likes, setHasUserLiked: setHasUserLiked, hasUserLiked: hasUserLiked, likeUrl: likeUrl })));
};
BlogDetail.defaultProps = {
    className: '',
};
export default BlogDetail;
