import React from 'react';
import './index.scss';
const Content = ({ className = '', parallaxStory }) => {
    const baseClassName = 'w-parallax-content';
    return (React.createElement("div", { className: `${baseClassName} ${className}` },
        React.createElement("p", { className: "rubric2" }, parallaxStory.rubric),
        React.createElement("p", { className: "header1" }, parallaxStory.title),
        React.createElement("div", { className: "lead", 
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML: { __html: parallaxStory.content } })));
};
export default Content;
