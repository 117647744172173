import React, { useState } from 'react';
import Lightbox from 'react-awesome-lightbox';
import GalleryImage from '../../atoms/galleryImage';
import './index.scss';
import 'react-awesome-lightbox/build/style.css';
const Gallery = ({ className, darken = false, images, title = '', }) => {
    const baseClassName = 'm-gallery';
    const [isGalleryOpen, setGalleryOpen] = useState(false);
    const [galleryIndex, setGalleryIndex] = useState(0);
    if (images.length === 0) {
        return React.createElement("div", { className: `${baseClassName} ${className}` });
    }
    return (React.createElement("div", { className: `${baseClassName} ${className}` },
        title !== '' && React.createElement("div", { className: `${baseClassName}__rubric rubric1` }, title),
        React.createElement("div", { className: `${baseClassName}__gallery` }, images.map((image, index) => {
            const key = `${index}`;
            const onImageClick = () => {
                setGalleryOpen(true);
                setGalleryIndex(index);
            };
            return (React.createElement(GalleryImage, { key: key, className: `${baseClassName}__image`, darken: darken, image: image, onClick: onImageClick }));
        })),
        isGalleryOpen && (React.createElement(Lightbox, { allowRotate: false, allowZoom: false, doubleClickZoom: 0, images: images.map((image, index) => ({
                title: image.alt,
                url: `${image.image}#index=${index}`, // Fix for images not loading properly
            })), onClose: () => setGalleryOpen(false), startIndex: galleryIndex }))));
};
Gallery.defaultProps = {
    className: '',
};
export default Gallery;
