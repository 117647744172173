import React from 'react';
import ImageResizer from '../../../../../../../../design/1/js/lib/ImageResizer';
import Breakpoint from '../../../../../../../../design/1/js/lib/types/Breakpoint';
import ContainerSizeMap from '../../../../../../../../design/1/js/lib/types/ContainerSizeMap';
import './index.scss';
import Rubric from '../../atoms/rubric';
import Title from '../../atoms/title';
import CircularButton from '../../../../../../../../design/1/js/templates/atoms/circularButton';
import { ButtonColor } from '../../../../../../../../design/1/js/templates/atoms/textButton';
import useBreakpoint from '../../../../../../../../design/1/js/lib/hooks/useBreakpoint';
import ReferenceType from '../../../lib/ReferenceType';
var TileAspectRatio;
(function (TileAspectRatio) {
    TileAspectRatio[TileAspectRatio["Desktop"] = 1.25] = "Desktop";
    TileAspectRatio[TileAspectRatio["Mobile"] = 1.7777777777777777] = "Mobile";
})(TileAspectRatio || (TileAspectRatio = {}));
const Tile = ({ className = '', element, type, }) => {
    const baseClassName = 'w-relatedElements-tile';
    const breakpoint = useBreakpoint();
    const imageFormatter = new ImageResizer(element.image);
    const imageSize = getImageSize(breakpoint);
    const image = imageFormatter.resize(imageSize.width, imageSize.height, element.imageCropModel);
    return (React.createElement("a", { className: `${baseClassName} ${className} -${type} background-dark`, href: element.link, style: { backgroundImage: `url(${image})` } },
        React.createElement("div", { className: `${baseClassName}__wrapper` },
            React.createElement(Rubric, { className: `${baseClassName}__rubric` }, element.subtitle),
            React.createElement(Title, { className: `${baseClassName}__title` }, element.title),
            React.createElement("div", { className: `${baseClassName}__button` },
                React.createElement(CircularButton, { color: ButtonColor.Primary, inverted: type === ReferenceType.Blog, useStroke: true })))));
};
function getImageSize(breakpoint) {
    const baseWidth = ContainerSizeMap[breakpoint];
    let aspectRatio;
    const proportions = {
        height: 1,
        width: 1,
    };
    if (baseWidth <= Breakpoint.Small) {
        aspectRatio = TileAspectRatio.Mobile;
        proportions.height = 1;
        proportions.width = 1;
    }
    else {
        aspectRatio = TileAspectRatio.Desktop;
        proportions.height = 1 / 3;
        proportions.width = 1 / 3;
    }
    return {
        aspectRatio,
        height: (baseWidth / aspectRatio) * proportions.height,
        width: baseWidth * proportions.width,
    };
}
export default Tile;
