import ReactDOM from 'react-dom';
import React from 'react';
import Spacing from './templates/atoms/spacing';
document.addEventListener('DOMContentLoaded', () => {
    const spacings = document.querySelectorAll('.plf-spacing');
    spacings.forEach(spacing => {
        const spacingHeight = spacing.dataset.spacingHeight;
        ReactDOM.render(React.createElement(Spacing, { spacingHeight: spacingHeight }), spacing);
    });
});
