import React from 'react';
import './index.scss';
const FooterEmblems = ({ className = '' }) => {
    const baseClassName = 'm-footerEmblems';
    return (React.createElement("div", { className: `${baseClassName} ${className}` },
        React.createElement("div", { className: `${baseClassName}__emblem -wirMachenProfis` },
            React.createElement("a", { className: `${baseClassName}__emblemLink`, href: Links.WirMachenProfis.link, target: "_blank", rel: "noreferrer" },
                React.createElement("img", { className: `${baseClassName}__emblemImage -wirMachenProfis -gray`, alt: Links.WirMachenProfis.alt, src: Links.WirMachenProfis.srcGray }),
                React.createElement("img", { className: `${baseClassName}__emblemImage -wirMachenProfis -color`, alt: Links.WirMachenProfis.alt, src: Links.WirMachenProfis.srcColor }))),
        React.createElement("div", { className: `${baseClassName}__emblem -swissMadeSoftware` },
            React.createElement("a", { className: `${baseClassName}__emblemLink`, href: Links.SwissMadeSoftware.link, target: "_blank", rel: "noreferrer" },
                React.createElement("img", { className: `${baseClassName}__emblemImage -swissMadeSoftware`, alt: Links.SwissMadeSoftware.alt, src: Links.SwissMadeSoftware.src }))),
        React.createElement("div", { className: `${baseClassName}__emblem -kununuScore` },
            React.createElement("a", { className: `${baseClassName}__emblemLink`, href: Links.KununuScore.link, target: "_blank", rel: "noreferrer" },
                React.createElement("img", { className: `${baseClassName}__emblemImage -kununuScore`, src: Links.KununuScore.src, alt: Links.KununuScore.alt })))));
};
// The emblem of WirMachenProfis can't simply use a grayscale filter,
// because the image would seem too dark.
const Links = {
    SwissMadeSoftware: {
        alt: 'Swiss Made Software',
        link: 'https://www.swissmadesoftware.org/',
        src: '/design/img/emblems/swiss_made_software.png',
    },
    WirMachenProfis: {
        alt: 'Wir Machen Profis',
        link: 'https://www.berufsbildungplus.ch/',
        srcColor: '/design/img/emblems/wir_machen_profis.png',
        srcGray: '/design/img/emblems/wir_machen_profis_bw.png',
    },
    KununuScore: {
        alt: 'Kununu',
        link: 'https://www.kununu.com/ch/intersim2?utm_source=widget&utm_campaign=widget_selfservice_scorelarge',
        src: '/design/img/emblems/kununu_score.svg',
    },
};
export default FooterEmblems;
