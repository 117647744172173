import React from 'react';
import ReactDOM from 'react-dom';
import { ButtonColor } from '../../../../../design/1/js/templates/atoms/textButton';
import LinkboxColor from './lib/LinkboxColor';
import Linkbox from '../../../../../design/1/js/templates/organisms/linkbox';
document.addEventListener('DOMContentLoaded', () => {
    const linkboxes = document.querySelectorAll('.plf-linkbox-wrapper');
    linkboxes.forEach(linkbox => {
        const props = JSON.parse(linkbox.dataset.linkboxProperties);
        const withLink = (props.color !== LinkboxColor.None);
        const buttonColor = (props.color === LinkboxColor.Blue) ? ButtonColor.Primary : ButtonColor.Secondary;
        ReactDOM.render(React.createElement(Linkbox, { buttonColor: buttonColor, buttonText: props.buttonTitle, backgroundColor: props.backgroundColor, hasButton: withLink, isExternalLink: props.isExternalLink, lead: props.lead, rubric: props.rubric, title: props.title, url: props.link }), linkbox);
    });
});
