import React from 'react';
import TileSet from '../../molecules/tileset';
import TextButton from '../../../../../../../../design/1/js/templates/atoms/textButton';
import './index.scss';
const Teaser = ({ allArticlesLink, articles, className = '' }) => {
    const baseClassName = 'w-blog-teaser';
    return (React.createElement("div", { className: `${baseClassName} ${className}` },
        React.createElement(TileSet, { articles: articles, className: `${baseClassName}__tiles` }),
        React.createElement("div", { className: `${baseClassName}__button` },
            React.createElement(TextButton, { url: allArticlesLink }, "Blogartikel durchst\u00F6bern"))));
};
export default Teaser;
