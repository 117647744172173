import React, { useRef } from 'react';
import './index.scss';
import ImageResizer, { cropModelToBackgroundPosition } from '../../../lib/ImageResizer';
import useElementDimensions from '../../../lib/hooks/useElementDimensions';
const ContactLayout = ({ children, className = '', image, imageCropModel, header, rubric, }) => {
    const baseClassName = 'm-contactLayout';
    const elementRef = useRef(null);
    const elementDimensions = useElementDimensions(elementRef);
    let backgroundUrl = null;
    if (elementDimensions !== null) {
        const imageResizer = new ImageResizer(image);
        backgroundUrl = imageResizer.resize(elementDimensions.width, elementDimensions.height, imageCropModel);
    }
    return (React.createElement("div", { ref: elementRef, className: `${baseClassName} ${className} background-secondary full-width-mobile-content` },
        React.createElement("div", { className: `${baseClassName}__background -image`, style: {
                backgroundImage: backgroundUrl && `url(${backgroundUrl})`,
                backgroundPosition: cropModelToBackgroundPosition(imageCropModel),
            } }),
        React.createElement("div", { className: `${baseClassName}__background -gradient` }),
        React.createElement("div", { className: `${baseClassName}__contentWrapper` },
            React.createElement("div", { className: `${baseClassName}__title rubric2` }, rubric),
            React.createElement("div", { className: `${baseClassName}__header header1` }, header),
            children)));
};
export default ContactLayout;
