import React, { useMemo, useRef } from 'react';
import './index.scss';
import TextInput from '../../../../../../../../design/1/js/templates/atoms/textInput';
import FileInput from '../../../../../../../../design/1/js/templates/atoms/fileInput';
import TextareaInput from '../../../../../../../../design/1/js/templates/atoms/textareaInput';
import Form from '../form';
import FileHelper from '../../../lib/FileHelper';
import SelectInput from '../../../../../../../../design/1/js/templates/molecules/selectInput';
const initialFormData = Object.freeze({
    applicationType: '',
    firstname: '',
    lastname: '',
    email: '',
    mobile: '',
    linkedIn: '',
    xing: '',
    otherLink: '',
    message: '',
    motivationLetterFile: {
        file: '',
        fileName: '',
        fileType: '',
    },
    resumeFile: {
        file: '',
        fileName: '',
        fileType: '',
    },
    additionalFiles: [],
});
const JobApplicationForm = ({ actionUrl, applicationTypes, recaptchaSiteKey }) => {
    const baseClassName = 'w-jobApplication-form';
    const applicationTypesMap = useMemo(() => {
        const typesNap = new Map();
        applicationTypes.forEach((value, key) => typesNap.set(key, value.name));
        return typesNap;
    }, [applicationTypes]);
    const defaultApplicationType = getDefaultApplicationType(applicationTypes);
    const customFileErrors = useRef({ motivationFile: undefined, resumeFile: undefined, additionalFiles: undefined });
    const fileHelper = useMemo(() => {
        const maxFileSize = 100 * 1024 * 1024; // 100 MB
        const allowedFileTypes = ['jpg', 'pdf'];
        return new FileHelper(maxFileSize, allowedFileTypes);
    }, []);
    const handleValidate = (values) => {
        const errors = {};
        if (values.firstname === '') {
            errors.firstname = 'Bitte Vornamen eingeben.';
        }
        if (values.lastname === '') {
            errors.lastname = 'Bitte Nachname eingeben.';
        }
        if (values.email === '') {
            errors.email = 'Bitte E-Mail eingeben.';
        }
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Bitte gültige E-Mail eingeben.';
        }
        return Promise.resolve(errors);
    };
    return (React.createElement(Form, { actionUrl: actionUrl, handleValidate: handleValidate, initialValues: Object.assign(Object.assign({}, initialFormData), { applicationType: defaultApplicationType }), fileFields: ['motivationLetterFile', 'resumeFile', 'additionalFiles'], name: "Bewerbung", recaptchaKey: recaptchaSiteKey, teaserTitle: "Bewirb dich jetzt", teaserContent: (React.createElement(React.Fragment, null,
            "Wir freuen uns auf deine",
            ' ',
            React.createElement("strong", null, "Bewerbung"))) }, formik => {
        var _a, _b, _c;
        const handleMotivationLetterChange = event => {
            fileHelper.getFileContent(event.target)
                .then(([file]) => {
                formik.setFieldValue('motivationLetterFile', file);
                customFileErrors.current.motivationFile = undefined;
            })
                .catch((error) => {
                formik.setFieldError('motivationLetterFile', error.message);
                customFileErrors.current.motivationFile = error.message;
            })
                .finally(() => formik.setFieldTouched('motivationLetterFile'));
        };
        const handleResumeFile = event => {
            fileHelper.getFileContent(event.target)
                .then(([file]) => {
                formik.setFieldValue('resumeFile', file);
                customFileErrors.current.resumeFile = undefined;
            })
                .catch((error) => {
                formik.setFieldError('resumeFile', error.message);
                customFileErrors.current.resumeFile = error.message;
            })
                .finally(() => formik.setFieldTouched('resumeFile'));
        };
        const handleAdditionalFilesChange = event => {
            fileHelper.getFileContent(event.target)
                .then(files => {
                formik.setFieldValue('additionalFiles', files);
                customFileErrors.current.additionalFiles = undefined;
            })
                .catch((error) => {
                formik.setFieldError('additionalFiles', error.message);
                customFileErrors.current.additionalFiles = error.message;
            })
                .finally(() => formik.setFieldTouched('additionalFiles'));
        };
        return (React.createElement(React.Fragment, null,
            React.createElement("p", { className: `${baseClassName}__requiredInfo` },
                React.createElement("span", null, "* Pflichtfelder")),
            React.createElement(SelectInput, { className: `${baseClassName}__selectInput`, id: "applicationType", label: "Bewerbung als", onBlur: formik.handleBlur, onChange: formik.handleChange, options: applicationTypesMap, placeholder: "Bitte ausw\u00E4hlen", required: true, value: formik.values.applicationType }),
            React.createElement(TextInput, { errorMessage: formik.touched.firstname && formik.errors.firstname ? formik.errors.firstname.toString() : '', id: "firstname", label: "Vorname", onBlur: formik.handleBlur, onChange: formik.handleChange, required: true, value: formik.values.firstname }),
            React.createElement(TextInput, { errorMessage: formik.touched.lastname && formik.errors.lastname ? formik.errors.lastname.toString() : '', id: "lastname", label: "Nachname", onBlur: formik.handleBlur, onChange: formik.handleChange, required: true, value: formik.values.lastname }),
            React.createElement("div", { className: `${baseClassName}__formRow` },
                React.createElement("div", { className: "flex-2" },
                    React.createElement(TextInput, { errorMessage: formik.touched.email && formik.errors.email ? formik.errors.email.toString() : '', id: "email", label: "E-Mail", onBlur: formik.handleBlur, onChange: formik.handleChange, required: true, value: formik.values.email })),
                React.createElement("div", { className: "flex-1" },
                    React.createElement(TextInput, { errorMessage: formik.touched.mobile && formik.errors.mobile ? formik.errors.mobile.toString() : '', id: "mobile", label: "Mobile", onBlur: formik.handleBlur, onChange: formik.handleChange, value: formik.values.mobile }))),
            React.createElement(FileInput, { accept: "application/pdf, image/jpeg", errorMessage: formik.touched.motivationLetterFile ? ((_a = customFileErrors.current.motivationFile) !== null && _a !== void 0 ? _a : formik.errors.motivationLetterFile) : '', fileName: formik.values.motivationLetterFile.fileName, onChange: handleMotivationLetterChange, id: "motivationLetter", label: "Motivationsschreiben und Referenzen (PDF, JPG, max. 100 MB)" }),
            React.createElement(FileInput, { accept: "application/pdf, image/jpeg", errorMessage: formik.touched.resumeFile ? ((_b = customFileErrors.current.resumeFile) !== null && _b !== void 0 ? _b : formik.errors.resumeFile) : '', fileName: formik.values.resumeFile.fileName, onChange: handleResumeFile, id: "resume", label: "Lebenslauf (PDF, JPG, max. 100 MB)" }),
            React.createElement(FileInput, { accept: "application/pdf, image/jpeg", errorMessage: formik.touched.additionalFiles ? ((_c = customFileErrors.current.additionalFiles) !== null && _c !== void 0 ? _c : formik.errors.additionalFiles) : '', fileName: formik.values.additionalFiles.map(file => file.fileName).join(', '), onChange: handleAdditionalFilesChange, id: "additionalFiles", label: "Weitere Dokumente (PDF, JPG, max. 100 MB)", multiple: true }),
            React.createElement(TextInput, { errorMessage: formik.touched.linkedIn && formik.errors.linkedIn ? formik.errors.linkedIn.toString() : '', id: "linkedIn", label: "LinkedIn Profil", onBlur: formik.handleBlur, onChange: formik.handleChange, value: formik.values.linkedIn }),
            React.createElement(TextInput, { errorMessage: formik.touched.xing && formik.errors.xing ? formik.errors.xing.toString() : '', id: "xing", label: "Xing Profil", onBlur: formik.handleBlur, onChange: formik.handleChange, value: formik.values.xing }),
            React.createElement(TextInput, { errorMessage: formik.touched.otherLink && formik.errors.otherLink ? formik.errors.otherLink.toString() : '', id: "otherLink", label: "Weiterer Link", onBlur: formik.handleBlur, onChange: formik.handleChange, value: formik.values.otherLink }),
            React.createElement(TextareaInput, { id: "message", label: "Bemerkung", onBlur: formik.handleBlur, onChange: formik.handleChange, value: formik.values.message })));
    }));
};
function getDefaultApplicationType(availableApplicationTypes) {
    const { searchParams } = new URL(document.location.toString());
    const selectedApplicationType = searchParams.get('applicationType');
    if (selectedApplicationType !== null && availableApplicationTypes.has(selectedApplicationType)) {
        return availableApplicationTypes.get(selectedApplicationType).id.toString();
    }
    const defaultApplicationType = Array.from(availableApplicationTypes.values()).find(applicationType => applicationType.placeholder);
    if (defaultApplicationType !== undefined) {
        return defaultApplicationType.id.toString();
    }
    return '';
}
export default JobApplicationForm;
