import React, { useRef, useState, useEffect } from 'react';
import WebglEffect from './lib/WebglEffect';
import HeroVariationBase from '../heroVariationBase';
const HeroVariationDefault = ({ className = '', parallaxHero, }) => {
    const baseClassName = 'w-parallax-heroVariationDefault';
    const webglWrapperRef = useRef(null);
    const webglCanvasRef = useRef(null);
    const [webglEffect, setWebglEffect] = useState(null);
    useEffect(() => {
        if (!webglEffect && !isGooglePageSpeedInsights()) {
            setWebglEffect(new WebglEffect(webglWrapperRef.current, webglCanvasRef.current));
        }
        return () => {
            if (webglEffect)
                webglEffect.destroy();
        };
    }, [webglEffect]);
    return (React.createElement(HeroVariationBase, { className: `${baseClassName} ${className}`, ref: webglWrapperRef, title: parallaxHero.title },
        React.createElement("canvas", { className: `${baseClassName}__canvas`, ref: webglCanvasRef })));
};
function isGooglePageSpeedInsights() {
    return /Chrome-Lighthouse/.test(navigator.userAgent);
}
export default HeroVariationDefault;
