import React from 'react';
import TextButton from '../../atoms/textButton';
import CircularButton from '../../atoms/circularButton';
import './index.scss';
import BackgroundColor from './backgroundColor';
const Linkbox = ({ buttonColor, backgroundColor = BackgroundColor.Gray, buttonText = null, className = '', hasButton = false, isExternalLink, lead, rubric, title, url, }) => {
    const baseClassName = 'o-linkbox';
    const target = isExternalLink ? '_blank' : '_self';
    return (React.createElement("div", { className: `${baseClassName} ${className} full-width-mobile-content` },
        React.createElement("div", { className: `${baseClassName}__container${backgroundColor === BackgroundColor.White ? ' bg-white' : ''}` },
            React.createElement("p", { className: "rubric2" }, rubric),
            React.createElement("p", { className: "header2" }, title),
            React.createElement("div", { dangerouslySetInnerHTML: { __html: lead } }),
            hasButton && (React.createElement("div", { className: `${baseClassName}__buttonWrapper` }, buttonText !== null && buttonText.trim().length > 0
                ? React.createElement(TextButton, { color: buttonColor, url: url, target: target }, buttonText)
                : React.createElement(CircularButton, { color: buttonColor, url: url, target: target }))))));
};
export default Linkbox;
