var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import Icon, { IconName } from '../../../../../../../../design/1/js/templates/atoms/icon';
import './index.scss';
import IconButton from '../../../../../../../../design/1/js/templates/atoms/iconButton';
var Links;
(function (Links) {
    Links[Links["Facebook"] = 0] = "Facebook";
    Links[Links["Twitter"] = 1] = "Twitter";
    Links[Links["LinkedIn"] = 2] = "LinkedIn";
    Links[Links["Xing"] = 3] = "Xing";
    Links[Links["Mail"] = 4] = "Mail";
})(Links || (Links = {}));
const linkList = Object.values(Links).filter(val => Number.isNaN(Number(val)));
const SocialBar = ({ likes, likeUrl, setHasUserLiked, hasUserLiked, }) => {
    const baseClassName = 'w-blogDetail-socialBar';
    const [showShareOptions, setShowShareOptions] = useState(false);
    const [localLikes, setLocalLikes] = useState(likes);
    const handleShareOptions = () => {
        setShowShareOptions(prevState => !prevState);
    };
    const handleLinking = link => {
        const actualUrl = document.URL.replace(/(^\w+:|^)\/\//, '');
        let url;
        switch (link) {
            case Links.Facebook:
                url = `https://www.facebook.com/sharer.php?u=http%3A%2F%2F${actualUrl}%2F`;
                break;
            case Links.Twitter:
                url = `https://twitter.com/intent/tweet?url=http%3A%2F%2F${actualUrl}%2F`;
                break;
            case Links.LinkedIn:
                url = `https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2F${actualUrl}%2F`;
                break;
            case Links.Xing:
                url = `https://www.xing.com/spi/shares/new?url=http%3A%2F%2F${actualUrl}%2F`;
                break;
            case Links.Mail:
                url = `mailto:?subject=Intersim AG Blogpost&body=https://${actualUrl}`;
                break;
            default:
                url = '';
        }
        if (url === '') {
            return;
        }
        window.open(url, '_blank');
    };
    const handleLike = () => __awaiter(void 0, void 0, void 0, function* () {
        if (hasUserLiked) {
            return;
        }
        // Optimistic update
        setLocalLikes(prevState => prevState + 1);
        try {
            const response = yield fetch(likeUrl, { method: 'POST', headers: { 'X-Csrf-Token': document.body.dataset.csrfToken } });
            if (!response.ok) {
                throw new Error('Network response error');
            }
            setHasUserLiked(true);
        }
        catch (e) {
            setLocalLikes(prevState => prevState - 1);
        }
    });
    return (React.createElement("div", { className: `${baseClassName}` },
        React.createElement("div", { className: `${baseClassName}__shareTriggers` },
            React.createElement("div", { className: `${baseClassName}__icon` },
                React.createElement(IconButton, { onClick: handleShareOptions },
                    React.createElement(Icon, { name: IconName.Share, useStroke: true, className: `${baseClassName}__triggerIcon` }))),
            React.createElement("div", { className: `${baseClassName}__icon` },
                React.createElement(IconButton, { onClick: handleLike, disabled: hasUserLiked },
                    React.createElement(Icon, { className: `${baseClassName}__triggerIcon -like ${hasUserLiked ? '-hasLiked' : ''}`, name: IconName.Like, useFill: hasUserLiked, useStroke: !hasUserLiked }))),
            React.createElement("span", { className: `${baseClassName}__likes` }, localLikes)),
        React.createElement("div", { className: `${baseClassName}__shareOptions ${showShareOptions ? '' : '-hidden'}` }, linkList.map(link => (React.createElement(IconButton, { className: "option", key: link, onClick: () => handleLinking(Links[link]) },
            React.createElement(Icon, { name: IconName[link], className: `${baseClassName}__shareIcon` })))))));
};
export default SocialBar;
