import React from 'react';
import Link from '../../atoms/link';
import Content from '../../atoms/content';
import './index.scss';
import AnimationWrapper from '../animationWrapper';
const Text = ({ className = '', parallaxStory }) => {
    const baseClassName = 'w-parallax-text';
    return (React.createElement("div", { className: `${baseClassName} ${className} background-primary` },
        React.createElement(AnimationWrapper, { className: `${baseClassName}__wrapper background-primary` },
            React.createElement(Content, { className: `${baseClassName}__content`, parallaxStory: parallaxStory }),
            React.createElement(Link, { parallaxStory: parallaxStory }))));
};
export default Text;
