import React from 'react';
import Tile from '../tile';
import './index.scss';
const TileSet = ({ className = '', relatedElements, type }) => {
    const baseClassName = 'w-relatedElements-tileSet';
    return (React.createElement("div", { className: `${baseClassName} ${className}` }, relatedElements.map((element, index) => {
        const key = `${element.id}-${index}`;
        return (React.createElement(Tile, { key: key, element: element, type: type, className: `${baseClassName}__tile` }));
    })));
};
export default TileSet;
