import React, { useRef } from 'react';
import CircularButton from '../../../../../../../../design/1/js/templates/atoms/circularButton';
import { ButtonColor } from '../../../../../../../../design/1/js/templates/atoms/textButton';
import './index.scss';
const ContactlinkButton = ({ className, link }) => {
    const baseClassName = 'w-contactLink-contactLinkButton';
    const anchorRef = useRef(null);
    const onClick = () => {
        var _a;
        (_a = anchorRef.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    return (React.createElement("div", { className: `${baseClassName} ${className}` },
        React.createElement("span", { className: `${baseClassName}__content display`, onClick: onClick },
            React.createElement("a", { className: `${baseClassName}__link`, ref: anchorRef, href: link.link }, link.linkTitle),
            React.createElement(CircularButton, { className: `${baseClassName}__button`, color: ButtonColor.Secondary, customDiameter: true, inverted: true }))));
};
export default ContactlinkButton;
