import React from 'react';
import ImageHeader from '../../../../../../../../design/1/js/templates/molecules/imageHeader';
import './index.scss';
const Header = ({ blogArticle, className = '' }) => {
    const baseClassName = 'w-blogDetail-header';
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "rubric1" },
            "BLOG /",
            blogArticle.title),
        React.createElement(ImageHeader, { className: `${baseClassName} ${className}`, cropModel: blogArticle.header.cropModel, hasScrollButton: true, image: blogArticle.header.image },
            React.createElement("h1", { className: `${baseClassName}__title display` }, blogArticle.title))));
};
export default Header;
