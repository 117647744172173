import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import 'swiper/swiper.scss';
import ContentGroup from '../../molecules/contentGroup';
import Indicators from '../../molecules/indicators';
import CurrentProjectButton from '../../atoms/currentProjectButton';
import './index.scss';
import ImageWrapper from '../../molecules/imageWrapper';
import SwiperHelper from '../../../lib/SwiperHelper';
SwiperCore.use([Autoplay]);
const MobileView = ({ className = '', index, onSelectIndex, references, }) => {
    const baseClassName = 'w-referenceCarousel-mobileView';
    const [swiper, setSwiper] = useState(null);
    const isIndexChangeAllowed = useRef(true);
    const isDuringAutoplay = useRef(false);
    const onSlideChange = (swiperInstance) => {
        if (isIndexChangeAllowed.current) {
            onSelectIndex(swiperInstance.realIndex);
            fixIndex(swiperInstance);
        }
    };
    const fixIndex = (swiperInstance) => {
        if (!isDuringAutoplay.current) {
            isIndexChangeAllowed.current = false;
            SwiperHelper.fixLoopIndex(swiperInstance);
            isIndexChangeAllowed.current = true;
        }
    };
    useEffect(() => {
        if (swiper === null || index === swiper.realIndex) {
            return;
        }
        swiper.slideToLoop(index);
    }, [index, swiper]);
    return (React.createElement("div", { className: `${baseClassName} ${className}` },
        React.createElement(Swiper, { autoplay: {
                delay: 10000,
            }, grabCursor: true, initialSlide: index, loop: true, loopedSlides: Math.max(2, references.length), onAutoplayStart: () => { isDuringAutoplay.current = true; }, onAutoplayStop: () => { isDuringAutoplay.current = false; }, onSwiper: setSwiper, onTransitionEnd: onSlideChange, speed: 1000 }, references.map((reference, referenceIndex) => {
            const key = `${referenceIndex}`;
            return (React.createElement(SwiperSlide, { key: key, className: `${baseClassName}__slide` },
                React.createElement(ImageWrapper, { className: `${baseClassName}__imageWrapper`, reference: reference },
                    React.createElement(CurrentProjectButton, { className: `${baseClassName}__imageButton` })),
                React.createElement(ContentGroup, { className: `${baseClassName}__content`, reference: reference })));
        })),
        React.createElement(Indicators, { length: references.length, onSelect: onSelectIndex, selectedIndex: index })));
};
export default MobileView;
