import React from 'react';
import Tile, { TileSize } from '../tile';
import './index.scss';
import useBreakpoint from '../../../../../../../../design/1/js/lib/hooks/useBreakpoint';
import Breakpoint from '../../../../../../../../design/1/js/lib/types/Breakpoint';
const TileSet = ({ articles, className = '', mirror = false }) => {
    const baseClassName = 'w-blog-tileSet';
    const breakpoint = useBreakpoint();
    return (React.createElement("div", { className: `${baseClassName} ${className} full-width-mobile-content ${mirror ? '-mirror' : ''}` }, articles.map((article, index) => {
        const tileSettings = getTileSettings(index, breakpoint);
        return (React.createElement(Tile, { key: article.id, article: article, className: `${baseClassName}__tile -${tileSettings.area}`, tileSize: tileSettings.size }));
    })));
};
var TileArea;
(function (TileArea) {
    TileArea["Primary"] = "primary";
    TileArea["Secondary"] = "secondary";
    TileArea["Tertiary"] = "tertiary";
    TileArea["Quartary"] = "quartary";
    TileArea["Quintary"] = "quintary";
})(TileArea || (TileArea = {}));
function getTileSettings(index, breakpoint) {
    let getTileSize;
    if (breakpoint >= Breakpoint.XLarge) {
        getTileSize = getDesktopTileSize;
    }
    else if (breakpoint >= Breakpoint.Medium) {
        getTileSize = getTabletTileSize;
    }
    else {
        getTileSize = getMobileTileSize;
    }
    switch (index % 5) {
        case 0:
            return {
                area: TileArea.Primary,
                size: getTileSize(index),
            };
        case 1:
            return {
                area: TileArea.Secondary,
                size: getTileSize(index),
            };
        case 2:
            return {
                area: TileArea.Tertiary,
                size: getTileSize(index),
            };
        case 3:
            return {
                area: TileArea.Quartary,
                size: getTileSize(index),
            };
        case 4:
        default:
            return {
                area: TileArea.Quintary,
                size: getTileSize(index),
            };
    }
}
function getDesktopTileSize(index) {
    switch (index) {
        case 0:
            return TileSize.DesktopBig;
        case 1:
            return TileSize.DesktopTall;
        case 2:
        case 3:
        case 4:
        default:
            return TileSize.DesktopSmall;
    }
}
function getTabletTileSize(index) {
    switch (index) {
        case 0:
            return TileSize.TabletWide;
        case 1:
        case 2:
        case 3:
        case 4:
        default:
            return TileSize.TabletNormal;
    }
}
function getMobileTileSize() {
    return TileSize.Mobile;
}
export default TileSet;
