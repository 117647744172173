import { useEffect } from 'react';
const useBodyScroll = (scrollVisible) => {
    const body = document.body;
    useEffect(() => {
        if (scrollVisible) {
            body.style.setProperty('--body-margin', '0px');
            body.style.marginRight = null;
            body.style.overflow = null;
        }
        else {
            body.style.setProperty('--body-margin', `${calculateScrollBarWidth()}px`);
            body.style.marginRight = 'var(--body-margin)';
            body.style.overflow = 'hidden';
        }
        return () => {
            body.style.setProperty('--body-margin', '0px');
            body.style.marginRight = null;
            body.style.overflow = null;
        };
    }, [scrollVisible]);
};
function calculateScrollBarWidth() {
    return window.innerWidth - document.body.clientWidth;
}
export default useBodyScroll;
