import { polyfill as scrollPolyfill } from 'seamless-scroll-polyfill';
import $ from 'jquery';
import 'featherlight';
import mediaQueryListenerPolyfill from './mediaQueryListenerPolyfill';
// Fixes <form>.requestSubmit() on Safari
import 'form-request-submit-polyfill';
export default function executeFixes() {
    // Fixes window.scrollTo on Safari
    scrollPolyfill();
    // Fixes MediaQueryList.addEventListener on Safari
    mediaQueryListenerPolyfill();
}
$(() => {
    // @ts-ignore
    $.featherlight.autoBind = false;
    // @ts-ignore
    $('[rel="svLightBox"]').each(function () {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'src' does not exist on type 'HTMLElement... Remove this comment to see the full error message
        const PartsOfTheUrl = this.src.split(/(.*_file\/[0-9]+)/);
        if (PartsOfTheUrl.length === 3) {
            $(this).attr('data-featherlight', PartsOfTheUrl[1]);
        }
        else {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'src' does not exist on type 'HTMLElement... Remove this comment to see the full error message
            $(this).attr('data-featherlight', this.src);
        }
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'true' is not assignable to param... Remove this comment to see the full error message
        $(this).attr('data-featherlight-persist', true);
        $(this).attr('data-featherlight-type', 'image');
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'featherlight' does not exist on type 'JQ... Remove this comment to see the full error message
        $(this).featherlight();
    });
});
