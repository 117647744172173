import React from 'react';
import { chunk } from 'lodash';
import TileSet from '../../molecules/tileset';
import './index.scss';
const GalleryView = ({ articles, className = '' }) => {
    const baseClassName = 'w-blog-galleryView';
    const groupedArticles = chunk(articles, 5);
    return (React.createElement("div", { className: `${baseClassName} ${className}` }, groupedArticles.map((articleGroup, index) => {
        const key = articleGroup.map(article => article.id).join('-');
        return (React.createElement(TileSet, { key: key, articles: articleGroup, className: `${baseClassName}__tileSet`, mirror: index % 2 !== 0 }));
    })));
};
export default GalleryView;
