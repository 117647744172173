import React, { useRef } from 'react';
import ScrollButton from '../../../../../../../../design/1/js/templates/atoms/scrollButton';
import Image from '../../atoms/image';
import HeroVariationDefault from '../../atoms/heroVariationDefault';
import './index.scss';
import ParallaxHeroVariation from '../../../lib/ParallaxHeroVariation';
import HeroVariationWinter2021 from '../../atoms/heroVariationWinter2021';
const heroVariations = {
    [ParallaxHeroVariation.DEFAULT]: HeroVariationDefault,
    [ParallaxHeroVariation.WINTER_2021]: HeroVariationWinter2021,
};
const Hero = ({ className = '', parallaxHero, }) => {
    const baseClassName = 'w-parallax-hero';
    const heroRef = useRef(null);
    const scrollDown = () => {
        if (heroRef.current === null) {
            return;
        }
        const sectionBottom = document.documentElement.scrollTop + heroRef.current.getBoundingClientRect().bottom;
        window.scrollTo({
            behavior: 'smooth',
            top: sectionBottom,
        });
    };
    const HeroVariation = heroVariations[parallaxHero.variation];
    return (React.createElement("div", { ref: heroRef, className: `${baseClassName} ${className}` },
        React.createElement(Image, { parallaxStory: parallaxHero, className: `${baseClassName}__image` }),
        React.createElement(HeroVariation, { parallaxHero: parallaxHero }),
        React.createElement("div", { className: `${baseClassName}__scroll` },
            React.createElement(ScrollButton, { animate: true, onClick: scrollDown, inverted: false }))));
};
export default Hero;
