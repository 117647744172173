import React, { useState } from 'react';
import TextButton, { ButtonColor } from '../../../../../../../../design/1/js/templates/atoms/textButton';
import SearchItemFetcher from '../../../lib/SearchItemFetcher';
import SearchTitle from '../../atoms/searchTitle';
import SearchItemView from '../../molecules/searchItemView';
import './index.scss';
const Search = ({ totalcount, searchstring, initialSearchItems, loadUrl, }) => {
    const baseClassName = 'w-search';
    const [searchItems, setSearchItems] = useState(initialSearchItems);
    const [page, setPage] = useState(2);
    const [hasMore, setHasMore] = useState(parseInt(totalcount, 10) > 10);
    const searchItemFetcher = new SearchItemFetcher(loadUrl);
    const fetchMore = (resetSearchItems = false) => {
        const requestPage = resetSearchItems ? 2 : page;
        searchItemFetcher.fetchSearchItems(searchstring, requestPage)
            .then(response => {
            const newSearchItems = resetSearchItems ? [] : searchItems.slice();
            newSearchItems.push(...response.searchItems);
            setSearchItems(newSearchItems);
            setHasMore(response.hasMore);
            setPage(requestPage + 1);
        });
    };
    const loadMoreCallback = () => {
        fetchMore();
    };
    return (React.createElement("div", { className: `${baseClassName}` },
        React.createElement(SearchTitle, { totalcount: totalcount, searchstring: searchstring, className: `${baseClassName}__searchTitle` }),
        React.createElement(SearchItemView, { searchItems: searchItems, className: `${baseClassName}__searchItemView` }),
        hasMore && (React.createElement("div", { className: `${baseClassName}__moreButton` },
            React.createElement(TextButton, { color: ButtonColor.Primary, showArrow: false, handleClick: loadMoreCallback }, "Weitere Resultate laden")))));
};
export default Search;
