import React from 'react';
import CircularButton from '../../../../../../../../design/1/js/templates/atoms/circularButton';
import { ButtonColor } from '../../../../../../../../design/1/js/templates/atoms/textButton';
import './index.scss';
const Link = ({ className = '', parallaxStory }) => {
    const baseClassName = 'w-parallax-link';
    return (React.createElement("a", { className: `${baseClassName} ${className}`, href: parallaxStory.link },
        React.createElement(CircularButton, { className: `${baseClassName}__button`, color: ButtonColor.Primary, inverted: true, customDiameter: true, useStroke: true })));
};
export default Link;
