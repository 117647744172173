import React from 'react';
import Icon, { IconName } from '../icon';
import './index.scss';
export var ButtonColor;
(function (ButtonColor) {
    ButtonColor["Primary"] = "primary";
    ButtonColor["Secondary"] = "secondary";
    ButtonColor["Dark"] = "dark";
    ButtonColor["GrayLight"] = "grayLight";
    ButtonColor["GrayDark"] = "grayDark";
})(ButtonColor || (ButtonColor = {}));
export var TextButtonSize;
(function (TextButtonSize) {
    TextButtonSize["Large"] = "large";
    TextButtonSize["Normal"] = "normal";
    TextButtonSize["Small"] = "small";
})(TextButtonSize || (TextButtonSize = {}));
const TextButton = ({ color = ButtonColor.Primary, className = '', children, handleClick, inverted = false, showArrow, size = TextButtonSize.Large, target = '_self', type, url, }) => {
    const baseClassName = 'a-textButton';
    const CustomTag = url ? 'a' : 'button';
    const isArrowShown = showArrow !== undefined ? showArrow : size === TextButtonSize.Large;
    return (React.createElement(CustomTag, { className: `${baseClassName} ${className} -${color} -${size} ${inverted ? '-inverted' : ''}`, href: url, onClick: handleClick, target: target, type: type },
        React.createElement("div", { className: `${baseClassName}__background` }),
        React.createElement("div", { className: `${baseClassName}__content` }, children),
        isArrowShown && React.createElement(Icon, { className: `${baseClassName}__arrow`, name: IconName.ArrowRight, useStroke: true })));
};
export default TextButton;
