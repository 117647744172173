import React from 'react';
import ReactDOM from 'react-dom';
import Divider from '../../../../../design/1/js/templates/molecules/divider';
document.addEventListener('DOMContentLoaded', () => {
    const dividers = document.querySelectorAll('.plf-divider');
    dividers.forEach(divider => {
        const props = JSON.parse(divider.dataset.dividerProperties);
        ReactDOM.render(React.createElement(Divider, { rubric: props.dividerTitle, smallSpacing: props.dividerIsMainTitle, title: props.dividerSubtitle }), divider);
    });
});
