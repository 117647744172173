import * as THREE from 'three';
// @ts-ignore TS2307: Cannot find module
import fragmentShader from './shader.frag';
// @ts-ignore TS2307: Cannot find module
import vertexShader from './shader.vert';
export default class WebglEffect {
    constructor(wrapper, element) {
        this.clock = new THREE.Clock();
        this.wrapper = wrapper;
        this.element = element;
        this.resize = this.resize.bind(this);
        this.start();
    }
    start() {
        this.setupRender();
        this.setSizes();
        this.init();
        this.resize();
        this.animate();
    }
    setupRender() {
        this.renderer = new THREE.WebGLRenderer({
            alpha: true,
            antialias: true,
            canvas: this.element,
        });
        this.renderer.outputEncoding = THREE.sRGBEncoding;
        this.scene = new THREE.Scene();
    }
    setSizes() {
        this.width = this.wrapper.offsetWidth;
        this.height = this.wrapper.offsetHeight;
    }
    resize() {
        this.setSizes();
        if (this.camera) {
            this.camera.aspect = this.width / this.height;
            this.camera.updateProjectionMatrix();
        }
        if (this.renderer) {
            this.renderer.setSize(this.width, this.height);
            this.renderer.setPixelRatio(window.devicePixelRatio);
        }
    }
    animate() {
        const elapsedTime = this.clock.getElapsedTime();
        this.material.uniforms.uTime.value = elapsedTime;
        this.render();
        requestAnimationFrame(() => this.animate());
    }
    render() {
        this.renderer.render(this.scene, this.camera);
    }
    init() {
        window.addEventListener('resize', this.resize);
        // MODEL
        const plane = new THREE.PlaneGeometry(2, 2, 512, 512);
        this.material = new THREE.ShaderMaterial({
            vertexShader,
            fragmentShader,
            uniforms: {
                uTime: { value: 0 },
                uBigWavesElevation: { value: 0.2 },
                uBigWavesFrequency: { value: new THREE.Vector2(4, 1.5) },
                uBigWavesSpeed: { value: 0.75 },
                uSmallWavesElevation: { value: 0.15 },
                uSmallWavesFrequency: { value: 3 },
                uSmallWavesSpeed: { value: 0.2 },
                uDepthColor: { value: new THREE.Color('#009dff') },
                uSurfaceColor: { value: new THREE.Color('#056fe1') },
                uColorOffset: { value: 0.08 },
                uColorMultiplier: { value: 5 },
            },
        });
        this.geometry = new THREE.Mesh(plane, this.material);
        this.geometry.position.set(0.01, 0.39, 0);
        this.geometry.rotation.set(-1.541, 0, 0);
        this.geometry.scale.set(1.6, 1.2, 1);
        this.scene.add(this.geometry);
        // CAMERA
        this.camera = new THREE.PerspectiveCamera(75, this.width / this.height, 0.1, 100);
        this.camera.position.set(0.11, 1.359, 0.005);
        this.camera.rotation.set(-1.541, 0.064, 1.516);
        this.camera.scale.set(1, 1, 1);
        this.scene.add(this.camera);
    }
    destroy() {
        window.cancelAnimationFrame(this.animationCallback);
        window.removeEventListener('resize', this.resize);
    }
}
