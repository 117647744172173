var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import './index.scss';
import RadioInputGroup from '../../../../../../../../design/1/js/templates/molecules/radioInputGroup';
import TextInput from '../../../../../../../../design/1/js/templates/atoms/textInput';
import Form from '../form';
const initialFormData = Object.freeze({
    gender: '',
    firstname: '',
    lastname: '',
    email: '',
});
const NewsletterForm = ({ actionUrl, recaptchaSiteKey }) => {
    const baseClassName = 'w-newsletter-form';
    const handleValidate = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const errors = {};
        if (values.firstname === '') {
            errors.firstname = 'Bitte Vornamen eingeben.';
        }
        if (values.lastname === '') {
            errors.lastname = 'Bitte Nachname eingeben.';
        }
        if (values.email === '') {
            errors.email = 'Bitte E-Mail eingeben.';
        }
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Bitte gültige E-Mail eingeben.';
        }
        return errors;
    });
    return (React.createElement(Form, { actionUrl: actionUrl, handleValidate: handleValidate, initialValues: initialFormData, name: "Newsletter Anmeldung", recaptchaKey: recaptchaSiteKey, teaserTitle: "Intersim Insights \u2013 jetzt registrieren!", teaserContent: (React.createElement(React.Fragment, null,
            "Wir berichten \u00FCber spannende Projekte und Use Cases, Innovationen und Beeindruckendes innerhalb und ausserhalb der Intersim kombiniert mit Management- und Strategieaspekten.",
            React.createElement("br", null),
            React.createElement("br", null),
            "Intersim Insights wird quartalsweise (4 Mal pro Jahr) versendet.")), useLoadSpinner: true }, formik => (React.createElement(React.Fragment, null,
        React.createElement("div", { className: `${baseClassName}__genderWrapper` },
            React.createElement("p", { className: `${baseClassName}__requiredInfo` }, "* Pflichtfelder"),
            React.createElement(RadioInputGroup, { name: "gender", onChange: formik.handleChange, radioInputs: [
                    {
                        id: 'female',
                        label: 'Frau',
                    },
                    {
                        id: 'male',
                        label: 'Herr',
                    },
                    {
                        id: 'neutral',
                        label: 'Neutral',
                    },
                ] })),
        React.createElement(TextInput, { errorMessage: formik.touched.firstname && formik.errors.firstname ? formik.errors.firstname.toString() : '', id: "firstname", label: "Vorname", onBlur: formik.handleBlur, onChange: formik.handleChange, required: true, value: formik.values.firstname }),
        React.createElement(TextInput, { errorMessage: formik.touched.lastname && formik.errors.lastname ? formik.errors.lastname.toString() : '', id: "lastname", label: "Nachname", onBlur: formik.handleBlur, onChange: formik.handleChange, required: true, value: formik.values.lastname }),
        React.createElement(TextInput, { errorMessage: formik.touched.email && formik.errors.email ? formik.errors.email.toString() : '', id: "email", label: "E-Mail", onBlur: formik.handleBlur, onChange: formik.handleChange, required: true, type: "text", value: formik.values.email })))));
};
export default NewsletterForm;
