import React from 'react';
import ReactDOM from 'react-dom';
import LogoSliderApp from './templates/organisms/logoSlider';
document.addEventListener('DOMContentLoaded', () => {
    const logoSliders = document.querySelectorAll('.plf-logoslider');
    logoSliders.forEach(slider => {
        const logoSliderPages = JSON.parse(slider.dataset.logos);
        renderLogoSlider(logoSliderPages, slider);
    });
});
const renderLogoSlider = (logos, slider) => {
    ReactDOM.render(React.createElement(LogoSliderApp, { logos: logos }), slider);
};
