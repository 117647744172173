import React, { useEffect, useRef, useState, } from 'react';
import './index.scss';
import Headroom from 'react-headroom';
import Icon, { IconName } from '../../atoms/icon';
import SearchInput from '../../molecules/searchInput';
import Flyout from '../flyout';
import useEventListener from '../../../lib/hooks/useEventListener';
import NavigationMenuItem from '../../atoms/navigationMenuItem';
const Header = ({ contactPage, menu, searchPage }) => {
    const baseClassName = 'o-header';
    const [isSearchVisible, setSearchVisible] = useState(false);
    const [isFlyoutVisible, setFlyoutVisible] = useState(false);
    const [isHeaderDisabled, setHeaderDisabled] = useState(false);
    const headerRef = useRef(null);
    const searchRef = useRef(null);
    useEventListener('inhibitHeader', (event) => {
        setHeaderDisabled(event.detail.inhibit);
    }, document);
    useEffect(() => {
        if (!isSearchVisible) {
            return () => { };
        }
        const outsideClickListener = (event) => {
            if (event.target instanceof Node && !searchRef.current.contains(event.target)) {
                setSearchVisible(false);
                document.removeEventListener('click', outsideClickListener);
            }
        };
        document.addEventListener('click', outsideClickListener);
        return () => document.removeEventListener('click', outsideClickListener);
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(Headroom, { className: `${baseClassName}`, onPin: () => {
                var _a;
                (_a = headerRef.current) === null || _a === void 0 ? void 0 : _a.classList.add('-pinned');
            }, onUnfix: () => {
                var _a;
                (_a = headerRef.current) === null || _a === void 0 ? void 0 : _a.classList.remove('-pinned');
            }, onUnpin: () => {
                var _a;
                (_a = headerRef.current) === null || _a === void 0 ? void 0 : _a.classList.remove('-pinned');
            }, style: { zIndex: 10 } },
            React.createElement("header", { ref: headerRef, className: `${baseClassName}__wrapper ${isHeaderDisabled ? '-disabled' : ''}` },
                React.createElement("div", { className: `${baseClassName}__desktopContent container` },
                    React.createElement(SearchInput, { autofocus: true, className: `${baseClassName}__search`, darken: true, isInputVisible: isSearchVisible, isInputVisibleOnHover: true, negativeMargin: true, ref: searchRef, searchPage: searchPage, setInputVisible: setSearchVisible }),
                    React.createElement("a", { className: `${baseClassName}__button -contact`, href: contactPage },
                        React.createElement("p", null, "Kontakt"),
                        React.createElement(Icon, { name: IconName.Contact, useStroke: true }))),
                React.createElement("div", { className: `${baseClassName}__content container` },
                    React.createElement("a", { className: `${baseClassName}__logo`, href: "/" },
                        React.createElement(Icon, { name: IconName.IntersimLogo })),
                    React.createElement("div", { className: `${baseClassName}__buttons` },
                        React.createElement("div", { className: `${baseClassName}__navItems` }, menu.sitemap
                            .map(navItem => React.createElement(NavigationMenuItem, { key: navItem.title, navItem: navItem }))),
                        React.createElement("div", { className: `${baseClassName}__button -burger`, onClick: () => setFlyoutVisible(true) },
                            React.createElement(Icon, { name: IconName.Burger, useStroke: true })))))),
        React.createElement(Flyout, { menu: menu, onClose: () => setFlyoutVisible(false), searchPage: searchPage, visible: isFlyoutVisible })));
};
export default Header;
