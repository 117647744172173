import React from 'react';
import './index.scss';
import ContactlinkButton from '../../atoms/contactlinkButton';
const Contactlinks = ({ title, links, }) => {
    const baseClassName = 'w-contactLinks-contactLinks';
    return (React.createElement("div", { className: `${baseClassName} background-secondary full-width-mobile-content` },
        React.createElement("p", { className: `${baseClassName}__rubric rubric2` }, title),
        links.map((link, index) => {
            const key = `${index}`;
            return (React.createElement(ContactlinkButton, { key: key, className: `${baseClassName}__link`, link: link }));
        })));
};
export default Contactlinks;
