import React from 'react';
import ItemBreadcrumb from '../../atoms/itemBreadcrumb';
import ItemTitle from '../../atoms/itemTitle';
import ItemContent from '../../atoms/itemContent';
import './index.scss';
const SearchItemComponent = ({ searchItem }) => {
    const baseClassName = 'w-search-searchItem';
    return (React.createElement("div", { className: `${baseClassName}` },
        React.createElement(ItemBreadcrumb, { className: `${baseClassName}__breadcrumb` }, searchItem.breadcrumb),
        React.createElement("a", { href: searchItem.path },
            React.createElement(ItemTitle, { title: searchItem.title, className: `${baseClassName}__title` })),
        React.createElement("a", { href: searchItem.path, className: `${baseClassName}__contentLink` },
            React.createElement(ItemContent, { content: searchItem.content, className: `${baseClassName}__content` }))));
};
export default SearchItemComponent;
