import React from 'react';
import ReactDOM from 'react-dom';
import Carousel from './templates/organisms/carousel';
document.addEventListener('DOMContentLoaded', () => {
    const carousels = document.querySelectorAll('.plf-reference-carousel');
    carousels.forEach(carousel => {
        const projectOverviewUrl = carousel.dataset.projectOverviewUrl;
        const references = JSON.parse(carousel.dataset.references);
        ReactDOM.render(React.createElement(Carousel, { projectOverviewUrl: projectOverviewUrl, references: references }), carousel);
    });
});
