import React from 'react';
import TextButton, { ButtonColor } from '../../../../../../../../design/1/js/templates/atoms/textButton';
import Contact from '../../molecules/contact';
import Divider from '../../../../../../../../design/1/js/templates/molecules/divider';
import './index.scss';
const JobDetail = ({ className = '', jobAdvert }) => {
    const baseClassName = 'w-jobDetail-jobDetail';
    return (React.createElement("div", { className: `${baseClassName} ${className}` },
        React.createElement(Divider, { className: `${baseClassName}__divider`, rubric: `Offene Stelle ${jobAdvert.department}`, smallSpacing: true, title: jobAdvert.title }),
        React.createElement("div", { className: `${baseClassName}__container` },
            jobAdvert.jobLead && jobAdvert.jobLead !== '' && (React.createElement("div", { className: `${baseClassName}__lead`, 
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML: { __html: jobAdvert.jobLead } })),
            jobAdvert.content && jobAdvert.content !== '' && (React.createElement("div", { className: `${baseClassName}__content`, 
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML: { __html: jobAdvert.content } })),
            React.createElement("div", { className: `${baseClassName}__buttonWrapper` },
                React.createElement(TextButton, { color: ButtonColor.Secondary, showArrow: false, url: jobAdvert.applicationFormLink }, "Bewerben"))),
        jobAdvert.contactPerson && React.createElement(Contact, { contact: jobAdvert.contactPerson })));
};
export default JobDetail;
