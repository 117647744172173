import React from 'react';
import SearchItemComponent from '../searchItem';
const SearchItemView = ({ className, searchItems }) => {
    const baseClassName = 'w-search-searchItemView';
    return (React.createElement("div", { className: `${baseClassName} ${className}` }, searchItems.map(searchItem => {
        const key = `searchItem-${searchItem.id}`;
        return React.createElement(SearchItemComponent, { key: key, searchItem: searchItem });
    })));
};
export default SearchItemView;
