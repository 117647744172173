import React from 'react';
import Indicator from '../../atoms/indicator';
import './index.scss';
const Indicators = ({ length, onSelect, selectedIndex }) => {
    const baseClassName = 'w-referenceCarousel-indicators';
    const indicators = range(length).map(index => (React.createElement(Indicator, { key: index, className: `${baseClassName}__indicator`, label: `Referenz ${index + 1} auswählen`, onClick: () => onSelect(index), selected: index === selectedIndex })));
    return (React.createElement("div", { className: baseClassName }, indicators));
};
function range(length) {
    return Array.from(Array(length).keys());
}
export default Indicators;
