import React, { useEffect } from 'react';
import Icon, { IconName } from '../../atoms/icon';
import DropdownArrow from '../../atoms/dropdownArrow';
import './index.scss';
const FilterDesktop = ({ children, className = '', formik, isOpen, setOpen, }) => {
    const baseClassName = 'o-filterDesktop';
    // If valid, automatically submit form after modifying
    useEffect(() => {
        formik.validateForm().then(errors => {
            const isTouched = isFormikTouched(formik);
            const isValid = Object.entries(errors).length === 0;
            if (isTouched && isValid && !formik.isSubmitting) {
                formik.submitForm();
            }
        });
    }, [formik.values]);
    return (React.createElement("div", { className: `${baseClassName} ${className} ${isOpen ? '-open' : ''}` },
        React.createElement("div", { className: `${baseClassName}__header`, onClick: () => setOpen(!isOpen) },
            React.createElement(Icon, { className: `${baseClassName}__icon`, name: IconName.Filter, useStroke: true }),
            React.createElement("div", { className: `${baseClassName}__text` }, "Filtern"),
            React.createElement(DropdownArrow, { className: `${baseClassName}__arrow`, open: isOpen, useStroke: true })),
        React.createElement("div", { className: `${baseClassName}__content` }, children)));
};
function isFormikTouched(formik) {
    const touchedEntries = Object.entries(formik.touched);
    return touchedEntries.length !== 0 && touchedEntries.every(([, value]) => value);
}
export default FilterDesktop;
