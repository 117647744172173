import React, { useRef } from 'react';
import Story from '../../molecules/story';
import Hero from '../../molecules/hero';
import './index.scss';
const Parallax = ({ parallaxStories, parallaxHero }) => {
    const baseClassName = 'w-parallax-parallax';
    const parallaxRef = useRef(null);
    return (React.createElement("div", { ref: parallaxRef, className: `${baseClassName}` },
        React.createElement(Hero, { parallaxHero: parallaxHero }),
        parallaxStories.map((parallaxStory, index) => (React.createElement(Story
        // eslint-disable-next-line react/no-array-index-key
        , { 
            // eslint-disable-next-line react/no-array-index-key
            key: index, mirrored: index % 2 === 1, parallaxStory: parallaxStory })))));
};
export default Parallax;
