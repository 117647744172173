import React from 'react';
import ContactLayout from '../../../../../../../../design/1/js/templates/molecules/contactLayout';
import CircularButton from '../../../../../../../../design/1/js/templates/atoms/circularButton';
import { ButtonColor } from '../../../../../../../../design/1/js/templates/atoms/textButton';
const PersonOfTheDay = ({ teamMember, teamOverviewPage }) => {
    const baseClassName = 'w-team-personOfTheDay';
    return (React.createElement("div", { className: baseClassName },
        React.createElement(ContactLayout, { image: teamMember.image, imageCropModel: teamMember.imageCropModel, header: teamMember.slogan, rubric: "Kopf des Tages" },
            React.createElement(CircularButton, { color: ButtonColor.Secondary, inverted: true, url: teamOverviewPage }))));
};
export default PersonOfTheDay;
