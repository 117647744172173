const SwiperHelper = {
    // Resets the current slide to a valid range,
    // preventing next or previous slides not being visible
    fixLoopIndex(swiperInstance) {
        const additionalSlides = getSwiperAdditionalSlides(swiperInstance);
        swiperInstance.slideTo(additionalSlides + swiperInstance.realIndex, 0);
    },
};
function getSwiperAdditionalSlides(swiperInstance) {
    return swiperInstance.slides.filter(slide => slide.classList.contains('swiper-slide-duplicate')).length / 2;
}
export default SwiperHelper;
