import React from 'react';
import './index.scss';
const LoadSpinner = ({ message = '', show = false }) => {
    const baseClassName = 'a-loadSpinner';
    return (React.createElement(React.Fragment, null, show && (React.createElement("div", { className: baseClassName },
        React.createElement("div", { className: `${baseClassName}__inner` },
            React.createElement("div", null),
            React.createElement("div", null),
            React.createElement("div", null)),
        React.createElement("p", null, message)))));
};
export default LoadSpinner;
