import React, { useEffect, useRef } from 'react';
import './index.scss';
import SnowShader from './lib/SnowShader';
import HeroVariationBase from '../heroVariationBase';
const HeroVariationWinter2021 = ({ className = '', parallaxHero, }) => {
    const baseClassName = 'w-parallax-heroVariationWinter2021';
    const canvasRef = useRef(null);
    useEffect(() => {
        if (!canvasRef.current) {
            return;
        }
        const snowShader = new SnowShader(canvasRef.current);
        snowShader.initialize();
    }, []);
    return (React.createElement(HeroVariationBase, { className: `${baseClassName} ${className}`, title: parallaxHero.title },
        React.createElement("div", { className: `${baseClassName}__canvas`, ref: canvasRef })));
};
export default HeroVariationWinter2021;
