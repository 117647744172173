import React from 'react';
import './index.scss';
const ClientPortrait = ({ className = '', reference }) => {
    const baseClassName = 'w-referencedetail-clientPortrait';
    return (React.createElement("div", { className: `${baseClassName} ${className}` },
        React.createElement("div", { className: `${baseClassName}__rubric rubric1` }, "Kundenportrait"),
        React.createElement("div", { className: `${baseClassName}__content` },
            reference.client.logo !== null && (React.createElement("div", { className: `${baseClassName}__logo`, style: {
                    backgroundImage: `url(${reference.client.logo})`,
                } })),
            React.createElement("div", { className: `${baseClassName}__info`, 
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML: { __html: reference.client.info } }))));
};
export default ClientPortrait;
