import React from 'react';
import './index.scss';
import CircularButton from '../../../../../../../../design/1/js/templates/atoms/circularButton';
import { ButtonColor } from '../../../../../../../../design/1/js/templates/atoms/textButton';
import OverflowText from '../../../../../../../../design/1/js/templates/atoms/overflowText';
const TopReferenceContent = ({ className = '', reference }) => {
    const baseClassName = 'w-referenceGallery-topReferenceContent';
    return (React.createElement("div", { className: `${baseClassName} ${className} background-primary` },
        React.createElement("div", { className: `${baseClassName}__content` },
            React.createElement("div", { className: `${baseClassName}__rubric rubric2` },
                "Projekt des Monats:",
                React.createElement("br", null),
                React.createElement(OverflowText, { lines: 2 }, reference.client)),
            React.createElement(OverflowText, { className: `${baseClassName}__title header2`, lines: 3 }, reference.name),
            React.createElement(OverflowText, { className: `${baseClassName}__lead lead`, lines: 5 },
                React.createElement("div", { dangerouslySetInnerHTML: { __html: reference.lead } })),
            React.createElement(CircularButton, { className: `${baseClassName}__arrow`, color: ButtonColor.Primary, inverted: true, useStroke: true }))));
};
export default TopReferenceContent;
