import React from 'react';
import LogoComponent from '../../atoms/logo';
import './index.scss';
import useBreakpoint from '../../../../../../../../design/1/js/lib/hooks/useBreakpoint';
import Breakpoint from '../../../../../../../../design/1/js/lib/types/Breakpoint';
const LogoSliderPage = ({ className = '', logos }) => {
    const breakpoint = useBreakpoint();
    const colSize = getColSize(breakpoint);
    return (React.createElement("div", { className: `row w-logoSlider-logoPage ${className}` }, logos.map((logo, index) => {
        const key = `logo-slider-logo-${index}`;
        return (React.createElement(LogoComponent, { key: key, logo: logo, className: `${colSize} w-logoSlider-logo` }));
    })));
};
function getColSize(breakpoint) {
    if (breakpoint <= Breakpoint.Small) {
        return 'col-6';
    }
    if (breakpoint <= Breakpoint.Large) {
        return 'col-3';
    }
    return 'col-2';
}
export default LogoSliderPage;
