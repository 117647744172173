const sideScale = 0.6;
const inverseSideScale = 1 - sideScale;
const sidePosition = 1 - (inverseSideScale / 2);
const ImageWrapperTransformer = {
    getTransform(relativeIndex) {
        const isGreatRange = Math.abs(relativeIndex) > 1;
        let scale;
        let translate;
        if (isGreatRange) {
            scale = sideScale;
            const sign = Math.sign(relativeIndex);
            const greatRangeIndex = relativeIndex - sign;
            translate = (sidePosition * sign) + (sideScale * greatRangeIndex);
        }
        else {
            scale = 1 - (inverseSideScale * Math.abs(relativeIndex));
            translate = sidePosition * relativeIndex;
        }
        return `translateX(${-translate * 100}%) translateX(calc(var(--size-gutter) * ${-relativeIndex})) scale(${scale})`;
    },
    getCrispImageOpacity(relativeIndex) {
        const opacity = 1 - Math.max(0, Math.min(Math.abs(relativeIndex), 1));
        return opacity < 0.01 ? 0 : opacity;
    },
};
export default ImageWrapperTransformer;
