export default class FileHelper {
    constructor(maxFileSize, allowedFileTypes) {
        this.maxFileSize = maxFileSize;
        this.allowedFileTypes = allowedFileTypes;
    }
    getFileContent(element) {
        const filesPromise = Array.from(element.files).map(file => this.getFilePromise(file));
        return Promise.all(filesPromise);
    }
    getFilePromise(file) {
        return new Promise((resolve, reject) => {
            const extension = file.name.split('.').pop().toLowerCase();
            const isValidFileType = this.allowedFileTypes.indexOf(extension) > -1;
            if (file.size > this.maxFileSize) {
                reject(new Error('Bitte kleinere Datei hochladen.'));
                return;
            }
            if (!isValidFileType) {
                reject(new Error('Unerlaubter Filetyp.'));
                return;
            }
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                resolve({
                    file: reader.result,
                    fileName: file.name,
                    fileType: file.type,
                    originalFile: file,
                });
            });
            reader.readAsDataURL(file);
        });
    }
}
