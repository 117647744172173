import { useEffect, useRef } from 'react';
export default function useIntersectionObserver(elementRef, options, callback) {
    const savedCallback = useRef();
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);
    useEffect(() => {
        const element = elementRef.current;
        if (element === null) {
            return () => { };
        }
        const observerCallback = (entries, observer) => savedCallback.current(entries, observer);
        const observer = new IntersectionObserver(observerCallback, options);
        observer.observe(element);
        return () => {
            observer.unobserve(element);
        };
    }, [elementRef.current, options.root, options.rootMargin, options.threshold]);
}
