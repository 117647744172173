import React from 'react';
import ReactDOM from 'react-dom';
import ContactForm from './templates/molecules/contactForm';
import NewsletterForm from './templates/molecules/newsletterForm';
import JobApplicationForm from './templates/molecules/jobApplicationForm';
document.addEventListener('DOMContentLoaded', () => {
    const contactFormList = document.querySelectorAll('.plf-forms-contactForm');
    for (const contactForm of contactFormList) {
        const formData = JSON.parse(contactForm.dataset.contactForm);
        const recaptchaSiteKey = contactForm.dataset.recaptchaSiteKey;
        ReactDOM.render(React.createElement(ContactForm, { actionUrl: formData.actionUrl, recaptchaSiteKey: recaptchaSiteKey }), contactForm);
    }
});
document.addEventListener('DOMContentLoaded', () => {
    const newsletterFormList = document.querySelectorAll('.plf-forms-newsletterForm');
    for (const newsletterForm of newsletterFormList) {
        const formData = JSON.parse(newsletterForm.dataset.newsletterForm);
        const recaptchaSiteKey = newsletterForm.dataset.recaptchaSiteKey;
        ReactDOM.render(React.createElement(NewsletterForm, { actionUrl: formData.actionUrl, recaptchaSiteKey: recaptchaSiteKey }), newsletterForm);
    }
});
document.addEventListener('DOMContentLoaded', () => {
    const jobApplicationFormList = document.querySelectorAll('.plf-forms-jobApplicationForm');
    for (const jobApplicationForm of jobApplicationFormList) {
        const formData = JSON.parse(jobApplicationForm.dataset.jobApplicationForm);
        const recaptchaSiteKey = jobApplicationForm.dataset.recaptchaSiteKey;
        ReactDOM.render(React.createElement(JobApplicationForm, { actionUrl: formData.actionUrl, applicationTypes: new Map(formData.options.applicationTypes), recaptchaSiteKey: recaptchaSiteKey }), jobApplicationForm);
    }
});
