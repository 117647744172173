import React from 'react';
import TopReferenceImage from '../../atoms/topReferenceImage';
import TopReferenceContent from '../../atoms/topReferenceContent';
import './index.scss';
const TopReference = ({ className = '', reference }) => {
    const baseClassName = 'w-referenceGallery-topReference';
    return (React.createElement("a", { className: `${baseClassName} ${className}`, href: reference.url },
        React.createElement(TopReferenceImage, { className: `${baseClassName}__image`, reference: reference }),
        React.createElement(TopReferenceContent, { className: `${baseClassName}__content`, reference: reference })));
};
export default TopReference;
