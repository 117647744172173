import React from 'react';
import ContactLayout from '../../../../../../../../design/1/js/templates/molecules/contactLayout';
import DecryptMailAnchor from '../../../../../../../../design/1/js/templates/atoms/decryptMailAnchor';
import './index.scss';
const Contact = ({ className = '', contact }) => {
    const baseClassName = 'w-jobdetail-contact';
    const firstname = contact.name.split(' ')[0];
    return (React.createElement(ContactLayout, { className: `${baseClassName} ${className}`, image: contact.image.image, imageCropModel: contact.image.cropModel, header: `${contact.name} antwortet dir gerne!`, rubric: "Fragen zur Bewerbung?" },
        React.createElement("div", { className: `${baseClassName}__address` },
            "Kirchbergstrasse 105",
            React.createElement("br", null),
            "3400 Burgdorf"),
        React.createElement("div", { className: `${baseClassName}__contact` },
            contact.phone.length > 0 && React.createElement("a", { href: `tel:${contact.phone}` }, contact.phone),
            React.createElement("br", null),
            React.createElement(DecryptMailAnchor, { email: contact.mail }, `E-Mail an ${firstname} schreiben`))));
};
export default Contact;
