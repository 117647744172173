import React from 'react';
import Header from '../../molecules/header';
import Content from '../../molecules/content';
import Testimonial from '../../molecules/testimonial';
import ProjectDetails from '../../molecules/projectDetails';
import Gallery from '../../../../../../../../design/1/js/templates/molecules/gallery';
import ClientPortrait from '../../molecules/clientPortrait';
import Lead from '../../molecules/lead';
import './index.scss';
const Reference = ({ className = '', reference }) => {
    const baseClassName = 'w-referenceDetail-reference';
    return (React.createElement("div", { className: `${baseClassName} ${className}` },
        React.createElement(Header, { className: `${baseClassName}__header`, reference: reference }),
        React.createElement(Lead, { className: `${baseClassName}__lead`, reference: reference }),
        React.createElement(Content, { className: `${baseClassName}__content`, reference: reference }),
        React.createElement(Testimonial, { className: `${baseClassName}__testimonial`, reference: reference }),
        React.createElement(ProjectDetails, { className: `${baseClassName}__projectDetails`, reference: reference }),
        reference.images.length > 0 && (React.createElement(Gallery, { className: `${baseClassName}__gallery`, darken: true, images: reference.images, title: "Weitere Bilder zu diesem Projekt" })),
        React.createElement(ClientPortrait, { className: `${baseClassName}__clientPortrait`, reference: reference })));
};
export default Reference;
