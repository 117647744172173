import React from 'react';
import './index.scss';
import GalleryTile from '../../molecules/galleryTile';
const Gallery = ({ className = '', members }) => {
    const baseClassName = 'w-team-gallery';
    return (React.createElement("div", { className: `${baseClassName} ${className} full-width-mobile-content` }, members.map((member, index) => (
    // eslint-disable-next-line react/no-array-index-key
    React.createElement(GalleryTile, { key: index, member: member })))));
};
export default Gallery;
