import React from 'react';
import './index.scss';
const BlogContent = ({ blogArticle, className = '' }) => {
    const baseClassName = 'w-blogDetail-blogContent';
    const publishDate = new Date(blogArticle.publishDate);
    const articleInfo = [
        ...blogArticle.authors.map(author => author.name),
        publishDate.toLocaleString('de-CH', { day: 'numeric', month: 'long', year: 'numeric' }),
        ...blogArticle.rubrics,
    ];
    return (React.createElement("div", { className: `${baseClassName} ${className}` },
        React.createElement("div", { className: `${baseClassName}__info rubric1` }, articleInfo.map((info, index) => (
        // eslint-disable-next-line react/no-array-index-key
        React.createElement("span", { key: index }, info)))),
        React.createElement("div", { className: `${baseClassName}__lead lead`, 
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML: { __html: blogArticle.lead } }),
        React.createElement("div", { className: `${baseClassName}__content`, 
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML: { __html: blogArticle.content } })));
};
export default BlogContent;
