import React from 'react';
const AnswerText = ({ status, message = '' }) => {
    const isFailure = (status !== 200);
    if (isFailure) {
        return (React.createElement(React.Fragment, null,
            React.createElement("h2", { className: "neg" }, "Hoppla, etwas ist schief gelaufen"),
            React.createElement("p", { className: "neg" }, message)));
    }
    return (
    // eslint-disable-next-line react/no-danger
    React.createElement("div", { dangerouslySetInnerHTML: { __html: message } }));
};
export default AnswerText;
