import React, { useEffect, useRef, useState } from 'react';
import './index.scss';
import DropdownArrow from '../../atoms/dropdownArrow';
import SplitActionButton from '../../atoms/splitActionButton';
import useUserAgent from '../../../lib/hooks/useUserAgent';
const SelectInput = ({ className = '', onBlur, onChange, id, label, options, placeholder, required, value, }) => {
    const baseClassName = 'm-selectInput';
    const dropdownRef = useRef();
    const [isOpen, setOpen] = useState(false);
    const userAgent = useUserAgent();
    const isMobile = userAgent.platform.type === 'mobile';
    const onClickItem = (itemValue) => {
        setOpen(false);
        onChange({ target: { id, value: itemValue } });
        // Timeout fixes stale values, see https://github.com/formium/formik/issues/2083
        setTimeout(() => onBlur({ target: { id } }));
    };
    useEffect(() => {
        if (!isOpen) {
            return () => { };
        }
        const outsideClickListener = (event) => {
            if (event.target instanceof Node && !dropdownRef.current.contains(event.target)) {
                setOpen(false);
                document.removeEventListener('click', outsideClickListener);
            }
        };
        document.addEventListener('click', outsideClickListener);
        return () => document.removeEventListener('click', outsideClickListener);
    }, [isOpen]);
    return (React.createElement("div", { className: `${baseClassName} ${className} ${isOpen ? '-open' : ''}` },
        label && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        React.createElement("div", { className: `${baseClassName}__label`, onClick: () => setOpen(true) }, `${label}${required ? ' *' : ''}`)),
        React.createElement("div", { ref: dropdownRef, className: `${baseClassName}__dropdown` },
            React.createElement(SplitActionButton, { action: (React.createElement(DropdownArrow, { className: `${baseClassName}__arrowIcon`, open: isOpen, useStroke: true })), className: `${baseClassName}__button`, onClick: () => setOpen(open => !open) }, value !== null && value !== '' ? options.get(value) : placeholder),
            isMobile && (React.createElement("select", { className: `${baseClassName}__mobileSelect`, onChange: event => onClickItem(event.target.value), value: value !== null && value !== void 0 ? value : '__placeholder' },
                React.createElement("option", { value: "__placeholder", disabled: true }, `\u2014 ${placeholder} \u2014`),
                Array.from(options.entries()).map(([optionKey, optionValue]) => (React.createElement("option", { key: optionKey, value: optionKey }, optionValue))))),
            React.createElement("div", { className: `${baseClassName}__list` }, Array.from(options.entries()).map(([optionKey, optionValue]) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
            React.createElement("div", { key: optionKey, className: `${baseClassName}__item ${value === optionKey ? '-selected' : ''}`, onClick: () => onClickItem(optionKey) }, optionValue)))))));
};
export default SelectInput;
