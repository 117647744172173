import React, { forwardRef, useEffect, useRef, useState, } from 'react';
import Icon, { IconName } from '../../atoms/icon';
import './index.scss';
const SearchInput = forwardRef(({ autofocus = false, className = '', darken = false, isInputVisible, isInputVisibleOnHover = false, negativeMargin = false, onClick, searchPage, setInputVisible, useStrokeWhenActive = true, }, ref) => {
    const baseClassName = 'm-searchInput';
    const [searchText, setSearchText] = useState('');
    const formRef = useRef(null);
    const inputRef = useRef(null);
    const buttonRef = useRef(null);
    useEffect(() => {
        var _a;
        if (autofocus && isInputVisible) {
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [isInputVisible]);
    useEffect(() => {
        var _a, _b;
        const onFocus = () => {
            setInputVisible(true);
        };
        const onBlur = (event) => {
            if (event.relatedTarget !== null && event.relatedTarget !== buttonRef.current) {
                setInputVisible(false);
            }
        };
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.addEventListener('focus', onFocus);
        (_b = inputRef.current) === null || _b === void 0 ? void 0 : _b.addEventListener('blur', onBlur);
        return () => {
            var _a, _b;
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('focus', onFocus);
            (_b = inputRef.current) === null || _b === void 0 ? void 0 : _b.removeEventListener('blur', onBlur);
        };
    });
    const onButtonClick = () => {
        var _a;
        if (isInputVisible) {
            if (onClick !== undefined) {
                onClick();
            }
            else {
                (_a = formRef.current) === null || _a === void 0 ? void 0 : _a.requestSubmit();
            }
        }
        else {
            setInputVisible(true);
        }
    };
    const onChangeText = event => setSearchText(event.target.value);
    const onSubmit = event => {
        if (searchText.trim().length === 0) {
            event.preventDefault();
        }
    };
    return (React.createElement("div", { className: `${baseClassName} ${className} ${isInputVisible ? '-active' : ''} ${darken ? '-darken' : ''} ${isInputVisibleOnHover ? '-hoverable' : ''} ${negativeMargin ? '-negativeMargin' : ''} ${useStrokeWhenActive ? '-useStrokeWhenActive' : ''}`, ref: ref },
        React.createElement("form", { action: searchPage, className: `${baseClassName}__form`, method: "POST", onSubmit: onSubmit, ref: formRef },
            React.createElement("input", { className: `${baseClassName}__input`, name: "Search[svSearch]", onChange: onChangeText, placeholder: "Suche", ref: inputRef, type: "search", value: searchText }),
            React.createElement("div", { className: `${baseClassName}__icon`, onClick: onButtonClick, ref: buttonRef, role: "button" },
                React.createElement(Icon, { name: IconName.Search, useStroke: true })))));
});
export default SearchInput;
